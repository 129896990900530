import React from "react";
import { Link } from "react-router-dom";

function ResultsList({ nurseries }) {
  return (
    <div className="mt-5">
      {nurseries.map(nursery => (
        <div key={nursery.AO_EAJE_id} style={{backgroundColor:"white", borderRadius: "10px", padding: "20px 30px 5px", fontFamily: "poppinsmedium", letterSpacing: "0.05em", fontSize: "0.8em", border: 0, marginBottom:"20px", boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.2)", display:"flex", justifyContent:"space-between", alignItems:"space-around", height:"auto"}} >
              <div>
                <h3>{nursery.AO_EAJE_name}</h3>
                <p style={{marginTop:"-20px"}}>
                  {`${nursery.AO_EAJE_zip} ${nursery.AO_EAJE_city}`}
                </p>
              </div>
              <Link to={"/creche/"+ nursery.AO_EAJE_id } ><button style={{backgroundColor:"#fcd260", color:"white", cursor:"pointer", borderRadius: "50px", padding: "10px 20px", fontFamily: "poppinsmedium", letterSpacing: "0.05em", fontSize: "0.8em", border: 0}} >Détails</button></Link>
        </div>
      ))}
    </div>
  );
}

export default ResultsList;
