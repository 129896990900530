import React, { Component, Fragment } from "react";
import axios from "axios";
import { MdRoom } from "react-icons/md";
import { connect } from "react-redux";
import {
  setCurrentLocation,
  filterByDistance,
  initializeNurseries
} from "../actions/searchActions";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

const API_URL = "https://maps.googleapis.com/maps/api/geocode/json";
const API_KEY = "AIzaSyBrWwzj5tzzX9MpBFXqfkKjzFYZIoGUuwI";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      addressMdAddLocation: "",
      lat: "",
      lng: "",
      address: ""
    };
  }

  componentDidMount() {
    const options = {
      types: [],
      componentRestrictions: { country: "fr" }
    };
    /*global google*/
    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields([
      "address_components",
      "formatted_address",
      "geometry"
    ]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  handlePlaceSelect = e => {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is validnpm install eslint
    if (address) {
      // Set State
      this.setState({
        query: address[0].long_name,
        address: this.search.value,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng()
      });
    }
  };

  Geolocalisation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      this.getAddress(lat, lng);
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
  };

  getAddress = (lat, lng, address) => {
    const latlng = `${lat},${lng}`;
    axios
      .get(`${API_URL}?latlng=${latlng}&key=${API_KEY}`)
      .then(response => response.data)
      .then(data => {
        this.setState({
          address: data.results[0].formatted_address
        });
      });
  };

  updateField = event => {
    this.setState({ address: event.target.value });
  };

  render() {
    return (
      <Fragment>
        <form
          onSubmit={event => {
            event.preventDefault();
            if (this.state.lat && this.state.lng && this.state.address) {
                this.props.dispatch(
                    setCurrentLocation(
                    this.state.lat,
                    this.state.lng,
                    this.state.address
                    )
                );
                this.props.dispatch(initializeNurseries());
                this.props.history.push("/rechercheCreche");
                }
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          <div style={{ position: "relative", marginBottom: "15px" }}>
            <input
              type="text"
              id="autocomplete"
              className="form-control form-control_home"
              style={{
                width: "100vw",
                maxWidth: "350px",
                borderRadius: "50px"
              }}
              placeholder="Chercher une crèche..."
              ref={input => (this.search = input)}
              onChange={this.updateField}
              value={this.state.address}
              required
            />
            <Button
              style={{
                backgroundColor: "white",
                color: "#390153",
                padding: "5px",
                position: "absolute",
                top: "0",
                right: "5px",
                boxShadow: "none"
              }}
              onClick={this.Geolocalisation}
            >
              <MdRoom style={{ width: "23px", height: "28px" }} />
            </Button>
          </div>
          <input className="search" type="submit" value="Rechercher" />
        </form>
      </Fragment>
    );
  }
}

export default withRouter(
  connect(({ searchReducer: { currentLocation, distanceFilter } }) => ({
    currentLocation,
    distanceFilter
  }))(SearchBar)
);
