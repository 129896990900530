import React, { Component } from "react";
import "./FormParents.css";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { IoIosMan, IoIosWoman } from "react-icons/io";

class FormParents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent: {
        lastName: "",
        firstName: "",
        gender: ""
      },
      emptyfield: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.clickMan = this.clickMan.bind(this);
    this.clickWomen = this.clickWomen.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  handleChange(event) {
    this.setState({
      parent: {
        ...this.state.parent,
        [event.target.name]: event.target.value
      }
    });
  }

  clickMan() {
    this.setState(state => ({
      parent: {
        ...this.state.parent,
        gender: "M"
      }
    }));
  }

  clickWomen() {
    this.setState(state => ({
      parent: {
        ...this.state.parent,
        gender: "F"
      }
    }));
  }

  nextStep() {
    const { lastName, firstName, gender } = this.state.parent;
    if (lastName !== "" && firstName !== "" && gender != "") {
      this.props.onClickNext(this.state);
    } else {
      this.setState({ emptyfield: "Champ manquant" });
    }
  }

  render() {
    const { lastName, firstName, emptyfield } = this.state;
    const { nextStep, clickMan, clickWomen, handleChange } = this;

    return (
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            padding: "80px 0px 50px 0"
          }}
        >
          <Button
            className={`buttonBis ${this.state.parent.gender === "F" &&
              "active"}`}
            style={{ borderRadius: "15px", marginRight: "30px" }}
            onClick={clickWomen}
          >
            <IoIosWoman style={{ width: "50px", height: "50px" }} />
            <br />
            Madame
          </Button>
          <Button
            className={`buttonBis ${this.state.parent.gender === "M" &&
              "active"}`}
            style={{ borderRadius: "15px", marginLeft: "30px" }}
            onClick={clickMan}
          >
            <IoIosMan style={{ width: "50px", height: "50px" }} />
            <br />
            Monsieur
          </Button>
        </div>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} xs={12}>
              <FormGroup>
                <FormLabel>Nom</FormLabel>
                <FormControl
                  type="nom"
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                  placeholder="Nom..."
                  className="form-control"
                />
              </FormGroup>
              <div style={{ paddingTop: "20px" }}>
                <FormGroup>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl
                    type="prenom"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    placeholder="Prénom..."
                    className="form-control"
                  />
                </FormGroup>
                <div
                  style={{
                    backgroundColor: "#FF4D4D",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "0.8em"
                  }}
                >
                  {emptyfield}
                </div>
                <div
                  style={{
                    padding: "50px 0px 150px 0px"
                  }}
                >
                  <Button
                    style={{ float: "right" }}
                    className="full_btn"
                    onClick={nextStep}
                  >
                    Suivant
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}
export default FormParents;
