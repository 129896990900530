import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RechercheCreche from "./pages/RechercheCreche";
import Contact from "./pages/Contact";
import Connexion from "./pages/Connexion";
import Creche from "./pages/Creche";
import NotreConcept from "./pages/NotreConcept";
import Faq from "./pages/Faq";
import FamilySignUp from "./pages/FamilySignUp";
import "./Style.css";
import Style from "./Style";
import ProDashboard from "./pages/ProDashboard";
import ProSignUp from "./components/ProSignUp";
import ProPreferencesCreche from "./pages/ProPreferencesCreche";
import User from "./pages/User";
import UserModif from "./pages/UserModif";
import UserResas from "./pages/UserResas";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import { refreshToken } from "./actions/authenticationActions";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPassword from "./pages/ResetPassword";
import MesCreches from "./pages/MesCreches";
import { setUser } from "./actions/userActions";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const accessToken = useSelector(
    state => state.authenticationReducer.accessToken
  );

  useEffect(() => {
    dispatch(refreshToken());
  }, []);

  useEffect(() => {
    if (accessToken && !user) dispatch(setUser());
  }, [accessToken]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/rechercheCreche" component={RechercheCreche} />
          <Route path="/notreConcept" component={NotreConcept} />
          <Route path="/contact" component={Contact} />
          <PublicRoute path="/connexion" component={Connexion} />
          <Route path="/faq" component={Faq} />
          <Route exact path="/ProDashboard" component={ProDashboard} />
          <Route exact path="/ProSignUp" component={ProSignUp} />
          <PublicRoute path="/FamilySignUp" component={FamilySignUp} />
          <Route path="/style" component={Style} />
          <Route exact path="/espacePro" component={ProDashboard} />
          <Route
            path="/espacePro/preferences"
            component={ProPreferencesCreche}
          />
          <Route path="/creche/:id" component={Creche} />
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute path="/usermodif" component={UserModif} />
          <PrivateRoute path="/user/reservations" component={UserResas} />
          <PrivateRoute path="/user/dashboard" component={MesCreches} />
          <PublicRoute path="/resetpassword" component={ResetPassword} />
          <PublicRoute
            path="/requestresetpassword"
            component={ResetPasswordRequest}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
