import React, { Component } from "react";
import { FormGroup, FormLabel, FormControl, Form, Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";

class ModifUserPerso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: "",
      firstName: "",
      phone: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeParent = this.handleChangeParent.bind(this);
    this.newInfoUser = this.newInfoUser.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeParent(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  newInfoUser() {
    const { lastName, firstName, phone } = this.state;
    if (lastName !== "" && firstName !== "" && phone !== "") {
      this.setState({ validation: "Données mises à jour" });
      this.sendNewInfoUser(this.state);
    } else {
      this.setState({ emptyFieldUser: "Champ manquant" });
    }
  }

  sendNewInfoUser = () => {
    const { phone, lastName, firstName } = this.state;
    axios
      .put(`/api/parents/me/infos/`, {
        AO_PARENT_name: lastName,
        AO_PARENT_firstname: firstName,
        AO_PARENT_phone: phone
      })
      .then();
  };
  render() {
    const {
      validation,
      emptyFieldUser,
      lastName,
      firstName,
      phone
    } = this.state;

    const { handleChangeParent, newInfoUser } = this;
    return (
      <div>
        <Form>
          <div style={{ padding: "20px"}} className="cadre">
            <div>
              <h3 style={{textAlign: "center"}}>Espace personnel</h3>
              <FormGroup controlId="formBasicEmail">
                <FormLabel>Nom</FormLabel>
                <FormControl
                  type="nom"
                  name="lastName"
                  value={lastName}
                  onChange={handleChangeParent}
                  placeholder="Nom"
                  className="form-control">
                </FormControl>
              </FormGroup>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <FormGroup controlId="formBasicEmail">
                <FormLabel>Prénom</FormLabel>
                <FormControl
                  type="prenom"
                  name="firstName"
                  value={firstName}
                  onChange={handleChangeParent}
                  placeholder="Prénom"
                  className="form-control">
                </FormControl>
              </FormGroup>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <FormGroup controlId="formBasicEmail">
                <FormLabel>Téléphone</FormLabel>
                <FormControl
                  type="Téléphone"
                  name="phone"
                  value={phone}
                  onChange={handleChangeParent}
                  placeholder="Téléphone"
                  className="form-control">
                </FormControl>
              </FormGroup>
              <div
                style={{
                  backgroundColor: "#FF4D4D",
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                {emptyFieldUser}
              </div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                {validation}
              </div>

              <Button
                style={{ float: "right", margin: "40px 0px 40px" }}
                className="button"
                onClick={newInfoUser}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
export default connect(({ authenticationReducer: { accessToken } }) => ({
  accessToken
}))(ModifUserPerso);
