import React, { useState, useEffect } from "react";
import axios from "axios";
import SlotCard from "./SlotCard";
import { connect } from "react-redux";
import { getAvailableSlots } from "../actions/availableSlotsActions";

const Slots = ({ availableSlots, dispatch, nurseryId, accessToken }) => {
  const [children, setChildren] = useState([{}]);

  useEffect(() => {
    dispatch(getAvailableSlots(nurseryId));

    axios
      .get(`/resa/enfants`)
      .then(response => {
        setChildren(response.data);
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px"
        }}
      >
        <h2 style={{ textAlign:'center'}}>Prochains créneaux disponibles</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center"
          }}
        >
          {availableSlots &&
            availableSlots.map((slot, i) => (
              <SlotCard
                slot={slot}
                children={children}
                key={i + "_slot" + Date.now()}
                nurseryId={nurseryId}
              />
            ))}
        </div>
        {availableSlots && (
          <p style={{ display: availableSlots.length > 0 ? "none" : "block" }}>
            Pas de créneaux disponibles avec les critères sélectionnés
          </p>
        )}
      </div>
    </div>
  );
};

export default connect(
  ({
    availableSlotsReducer: { availableSlots },
    authenticationReducer: { accessToken }
  }) => ({
    availableSlots,
    accessToken
  })
)(Slots);
