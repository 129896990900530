import React, { useState } from 'react';
import { FormGroup, FormLabel, FormControl, Form, Button, Row, Col } from 'react-bootstrap';
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";

const ProResa = ({ updateFields, values  }) => {
    const [checked, setChecked] = useState(false)

    return (
        <div className="cadre"  style={{ marginTop:'30px'}}>
            <h2>Réservations</h2>
            <Form.Group className="bg_purple_dark" style={{ padding: "5px 10px" }} controlId="formBasicCheckbox">
                <Form.Check
                    name="AO_EAJE_partialBooking"
                    onChange={e => setChecked(e.target.checked)}
                    checked={checked.AO_EAJE_partialBooking}
                    type="checkbox"
                    label="Réservation partielle possible"
                />
            </Form.Group>
            <p>Plages sans arrivée/départ :</p>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCheckbox">
                        <FaPlaneArrival />
                        <Form.Check
                            name="AO_NOAD_noa"
                            onChange={e => setChecked(e.target.checked)}
                            checked={checked.AO_NOAD_noa}
                            type="checkbox"
                            label="Pas d'arrivée sur cette plage" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCheckbox">
                        <FaPlaneDeparture />
                        <Form.Check
                            name="AO_NOAD_nod"
                            onChange={e => setChecked(e.target.checked)}
                            checked={checked.AO_NOAD_nod}
                            type="checkbox"
                            label="Pas de départ sur cette plage" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Début</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_NOAD_start}
                            name="AO_NOAD_start"
                            type="time"
                        />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Fin</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_NOAD_end}
                            name="AO_NOAD_end"
                            type="time"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Form.Group className="bg_purple_dark" style={{ padding: "5px 10px", marginTop: "15px" }} controlId="formBasicCheckbox">
                <Form.Check
                    type="checkbox"
                    label="Limite de réservation"
                />
            </Form.Group>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Début</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_bookDelay}
                            name="AO_EAJE_bookDelay"
                            type="time"
                        />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Fin</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_bookLimit}
                            name="AO_EAJE_bookLimit"
                            type="time"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <FormLabel>Note aux réservataires</FormLabel>
                <FormControl
                    onChange={updateFields}
                    value={values.AO_EAJE_bookComment}
                    name="AO_EAJE_bookComment"
                    type="text"
                    placeholder="ex: Non remboursable en cas d'annulation"
                />
            </FormGroup>
        </div>
    )
}

export default ProResa;