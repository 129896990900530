import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import axios from "axios";
import jwt from "jsonwebtoken";

function ResetPassword() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const decodedToken = jwt.decode(token);
  console.log(decodedToken.exp < Date.now() / 1000);

  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [confirmationMessage, displayConfirmationMessage] = useState(false);
  const [errorMessage, displayErrorMessage] = useState(false);

  const changePassword = event => {
    event.preventDefault();
    if (password === confirmationPassword) {
      displayErrorMessage(false);
      axios({
        method: "put",
        url: "/api/parents/me/infos",
        data: {
          AO_PARENT_pwd: password
        },
        headers: { Authorization: "Bearer " + token }
      }).then(() => {
        displayConfirmationMessage(true);
      });
    } else displayErrorMessage(true);
  };

  if (decodedToken.exp && Date.now() / 1000 < decodedToken.exp)
    return (
      <>
        <Header />
        <div className="bg_babily">
          <Container style={{ padding: "100px 0 150px 0" }}>
            <Card
              className="bg_purple_dark form_pro"
              style={{
                borderRadius: "20px",
                width: "100%",
                padding: "20px"
              }}
            >
              <Card.Body>
                <h2 style={{ color: "white", textAlign: "center" }}>
                  REINITIALISATION DE MOT DE PASSE
                </h2>
                <Form onSubmit={changePassword}>
                  <FormGroup>
                    <FormLabel>
                      Veuillez renseigner votre nouveau mot de passe.
                    </FormLabel>
                    <FormControl
                      type="password"
                      placeholder="Mot de passe"
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>Veuillez confirmer le mot de passe.</FormLabel>
                    <FormControl
                      type="password"
                      placeholder="Confirmation"
                      onChange={e => {
                        setConfirmationPassword(e.target.value);
                      }}
                      value={confirmationPassword}
                      required
                    />
                  </FormGroup>
                  {confirmationMessage && (
                    <p>
                      Votre mot de passe a bien été mis à jour, vous pouvez vous
                      connecter à l'aide de vos nouveaux identifiants.
                    </p>
                  )}
                  {errorMessage && (
                    <p>Les adresses email saisies ne correspondent pas.</p>
                  )}
                  <div className="form_pro_btn">
                    <Button type="submit" className="button">
                      Confirmer
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </div>
        <Footer />
      </>
    );
  else
    return (
      <>
        <Header />
        <p style={{ textAlign: "center", marginTop: 40 }}>
          Le lien de réinitialisation n'existe pas ou a expiré.
        </p>
        <Footer />
      </>
    );
}

export default ResetPassword;
