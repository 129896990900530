import React, { Component } from "react";
import { FormGroup, FormLabel, FormControl, Form, Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";

class ModifUserConexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailConfirmation: "",
      passwordBis: "",
      falseEmail: "",
      falsePassword: "",
      emptyField: "",
      validationConnexion: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeParent = this.handleChangeParent.bind(this);
    this.newInfoConnexionUser = this.newInfoConnexionUser.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeParent(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  newInfoConnexionUser() {
    const { email, password, emailConfirmation, passwordBis } = this.state;
    if (
      password !== "" &&
      passwordBis !== "" &&
      emailConfirmation !== "" &&
      email !== ""
    ) {
      if (email === emailConfirmation) {
        if (password === passwordBis) {
          this.setState({ validationConnexion: "Données mises à jour" });
          this.sendNewInfoUserConnexion(this.state);
        } else this.setState({ falsePassword: "Mot de passe non valide" });
      } else {
        this.setState({ falseEmail: "Email non valide" });
      }
    } else {
      this.setState({ emptyField: "Champ manquant" });
    }
  }

  sendNewInfoUserConnexion = () => {
    const { email, password } = this.state;
    axios
      .put(`/api/parents/me/infos/`, {
        AO_PARENT_email: email,
        AO_PARENT_pwd: password
      })
      .then();
  };

  render() {
    const {
      passwordBis,
      emailConfirmation,
      emptyField,
      validationConnexion,
      falseEmail,
      falsePassword,
      email,
      password
    } = this.state;
    const { handleChange, handleChangeParent, newInfoConnexionUser } = this;

    return (
      <Form>
        <div style={{ padding: "20px" }} className="cadre">
          <div>
            <h3 style={{textAlign: "center"}}>Espace connexion</h3>
            <FormGroup controlId="formBasicEmail">
              <FormLabel>Email</FormLabel>
              <FormControl
                type="email"
                name="email"
                value={email}
                onChange={handleChangeParent}
                placeholder="Email"
                className="form-control">
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <FormGroup controlId="formBasicEmail">
              <FormLabel>Confirmation email</FormLabel>
              <FormControl
                type="email"
                name="emailConfirmation"
                value={emailConfirmation}
                onChange={handleChange}
                placeholder="Confirmation email"
                className="form-control">
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <FormGroup controlId="formBasicEmail">
              <FormLabel>Mot de passe</FormLabel>
              <FormControl
                type="password"
                name="password"
                value={password}
                onChange={handleChangeParent}
                placeholder="Mot de passe"
                className="form-control">
              </FormControl>
            </FormGroup>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <FormGroup controlId="formBasicEmail">
              <FormLabel>Confirmation mot de passe</FormLabel>
              <FormControl
                type="password"
                name="passwordBis"
                value={passwordBis}
                onChange={handleChange} 
                placeholder="Confirmation mot de passe"
                className="form-control">
              </FormControl>
            </FormGroup>
            <div
              style={{
                backgroundColor: "#FF4D4D",
                color: "white",
                marginTop: "10px",
                textAlign: "center"
              }}
            >
              {emptyField}
            </div>
            <div
              style={{
                backgroundColor: "#FF4D4D",
                color: "white",
                marginTop: "10px",
                textAlign: "center"
              }}
            >
              {falseEmail}
            </div>
            <div
              style={{
                backgroundColor: "#FF4D4D",
                color: "white",
                marginTop: "10px",
                textAlign: "center"
              }}
            >
              {falsePassword}
            </div>
            <div
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: "10px",
                textAlign: "center"
              }}
            >
              {validationConnexion}
            </div>
            <div>
              <Button
                style={{ float: "right", marginTop: "40px" }}
                className="button"
                onClick={newInfoConnexionUser}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
export default connect(({ authenticationReducer: { accessToken } }) => ({
  accessToken
}))(ModifUserConexion);
