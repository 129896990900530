import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { host } from "../conf";
import logoDefault from "../assets/logo_default.png";


const CrecheFav = props => {
    const { creche } = props;

    return (
        <div style={{ padding: "20px", boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.2)", backgroundColor: "white", marginTop: "30px", width: "400px" }}>
            <Link style={{ textDecoration: "none" }} to={"/creche/" + creche.AO_EAJE_id}>
                <div style ={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    marginBottom:'20px'
                }}>
                <img
                    style={{
                        margin: "5px 25px 5px 5px",
                        width: "50px",
                        height: "50px",
                        objectFit: "cover"
                    }}
                    src={`${host}/pro/nursery/${creche.AO_EAJE_id}/preferences/logo`}
                    alt="logo"
                />
                <h2 style={{ marginBottom:'0'}}>{creche.AO_EAJE_name}</h2>
                </div>

                <h3>CONTACT</h3>
                <p>{creche.AO_EAJE_address}</p>
                <p>{creche.AO_EAJE_zip} {creche.AO_EAJE_city}</p>
                <p>{creche.AO_EAJE_email}</p>
                <p>{creche.AO_EAJE_phone}</p>
                <p style={{ textTransform: "uppercase", backgroundColor: creche.AO_QUEUE_status > 0 ? "#7ef2b9" : "#ffb973", color: "white", padding: "10px", width: "70%", marginTop: "30px" }}>STATUT DE {creche.AO_CHILD_firstname} : {creche.AO_QUEUE_status > 0 ? "inscrit.e" : "pas encore inscrit.e"} </p>
            </Link>
        </div>
    )
}

export default connect(
    ({ userReducer: { creches }, authenticationReducer: { accessToken } }) => ({
        creches,
        accessToken
    })
)(CrecheFav);