const initState = {
    currentPage: null
};

const currentPageReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_CURRENT_PAGE":
            return { currentPage: action.currentPage };
        default:
            return state;
    }
};

export default currentPageReducer;