import React, { Fragment } from "react";
import { Row, Col } from 'react-bootstrap';

function Temoignages(props) {
    return (
        <Fragment>
            <Col md={5} xs={12}>
                <p style={{ color: "#390153", paddingBottom: "20px", borderBottom: "2px dashed #390153" }}>{props.n.AO_TESTIMONY_text}</p>
                <p style={{ color: "#390153", fontWeight:'bold', fontSize:'1.1em'}}>{props.n.AO_TESTIMONY_name} {props.n.AO_TESTIMONY_lastname}</p>
            </Col>
        </Fragment>
    )
}

export default Temoignages;