import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FormEnfant from "../components/FormEnfant";
import FormParents from "../components/FormParents";
import FormEmail from "../components/FormEmail";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import axios from "axios";

class FamilySignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          title: "Informations personnelles"
        },
        {
          title: `Informations sur l'enfant `
        },
        {
          title: "Informations de connexion"
        }
      ],
      currentStep: 0,
      modal: true
    };
    this.onClickBefore = this.onClickBefore.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SendAllForm = this.SendAllForm.bind(this);
  }

  onClickBefore() {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1
    });
  }

  onClickNext(info) {
    const { currentStep } = this.state;
    this.setState(
      {
        currentStep: currentStep + 1,
        ...info
      },
      () => {
        if (this.state.currentStep === 3) this.SendAllForm();
      }
    );
  }

  handleChange(infoRemontee) {
    this.setState({
      ...infoRemontee
    });
  }

  SendAllForm = () => {
    const { parent, children } = this.state;
    const { email, telephone, password, isChecked } = this.state.infoConnection;
    axios
      .post(`/api/FamilySignUp/Parent/`, {
        AO_PARENT_name: parent.lastName,
        AO_PARENT_firstname: parent.firstName,
        AO_PARENT_gender: parent.gender,
        AO_PARENT_email: email,
        AO_PARENT_phone: telephone,
        AO_PARENT_pwd: password,
        AO_PARENT_cgu: isChecked,
        AO_PARENT_cafnum: "",
        AO_PARENT_address: "",
        AO_PARENT_zip: "",
        AO_PARENT_city: ""
      })
      .then(res => {
        console.log(res.data.insertId);
        axios
          .post(`/api/FamilySignUp/${res.data.insertId}/Childrens/`, children)
          .then(res => {
            console.log(res.data);
          });
      });
  };

  render() {
    const { steps, currentStep } = this.state;
    const { onClickBefore, onClickNext, handleChange, SendAllForm } = this;
    if (currentStep === steps.length) return <Redirect to="/user" />;

    return (
      <div>
        <Header />
        <Container>
          <div style={{ paddingTop: "80px" }}>
            <h1>INSCRIPTION</h1>
            <Stepper
              steps={steps}
              activeStep={currentStep}
              disabledSteps={[3]}
              defaultColor="#9e50c2"
              activeColor="#390153"
              completeColor="#390153"
              defaultTitleColor="#9e50c2"
              activeTitleColor="#390153"
              completeTitleColor="#390153"
              defaultBarColor="#9e50c2"
              completeBarColor="#390153"
              circleFontSize={0}
              size={24}
            />
          </div>
          <div>
            {currentStep === 0 && (
              <FormParents
                onClickNext={onClickNext}
                handleChange={handleChange}
              />
            )}
            {currentStep === 1 && (
              <FormEnfant
                onClickBefore={onClickBefore}
                onClickNext={onClickNext}
                handleChange={handleChange}
              />
            )}
            {currentStep === 2 && (
              <FormEmail
                onClickBefore={onClickBefore}
                onClickNext={onClickNext}
                handleChange={handleChange}
                SendAllForm={SendAllForm}
              />
            )}
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default FamilySignUp;
