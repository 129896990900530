import React from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';

const ProHours = ({ updateFields, values }) => {
    return (
        <div className="cadre">
            <h2>Horaires d'accueil</h2>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Ouverture</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_opening}
                            name="AO_EAJE_opening"
                            type="time" />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Fermeture</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_closing}
                            name="AO_EAJE_closing"
                            type="time" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Début du repas</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_noon}
                            name="AO_EAJE_noon"
                            type="time" />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Fin du repas</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_afternoon}
                            name="AO_EAJE_afternoon"
                            type="time" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Durée minimum</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_minBookDuration}
                            name="AO_EAJE_minBookDuration"
                            type="time" />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <FormLabel>Complément minimum</FormLabel>
                        <FormControl
                            onChange={updateFields}
                            value={values.AO_EAJE_minBookExtent}
                            name="AO_EAJE_minBookExtent"
                            type="time" />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default ProHours;

