import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ResultsContainer from "../containers/ResultsContainer";
import { Row, Col } from "react-bootstrap";
import SearchBar from "../components/SearchBar";
import Filters from "../containers/Filters";
import Map from "../components/Map";
import { connect } from "react-redux";
import ResultsList from "../components/ResultsList";
import "../components/Map.css"

const RechercheCreche = props => {

  let [showMap, setShowMap] = useState(false);

  const mapStyleLarge = {
    position: "fixed",
    width: "50vw",
    height: "92vh",
    marginTop: -20
  };
  
  const mapStyleSmall = {
    width: "100vw",
    height: "100vw",
    marginTop: -20, 
    left:"15px"
  };

  return (
    <div>
      <Header />

      <Row style={{ marginTop: "20px" }}>
        <Col md={{ span: 4, offset: 1 }}>
          <Row>
            <Col md="12">
              <SearchBar />
            </Col>
          </Row>
          <Row className="mt-5">
            <Filters />
          </Row>
          <Row>
            <Col md="12">
              <p
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                  textAlign: "center"
                }}
              >
                Résultats à proximité de :<br />
                {props.currentLocation.address}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <ResultsList nurseries={props.filteredNurseries} />
              <div style={{width:"100%", display:"flex", justifyContent:"center", marginBottom:"40px"}}>
                <div className="d-md-none" style={{backgroundColor:"#9e50c2", color:"white", cursor:"pointer", borderRadius: "50px", padding: "10px 20px", fontFamily: "poppinsmedium", letterSpacing: "0.05em", fontSize: "0.8em", border: 0}} onClick={() => setShowMap(!showMap)}>
                  {showMap ? "Fermer la carte": "Afficher la carte"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md="7">
            <div className={showMap ? "d-block d-md-block" : "d-none d-md-block"}>
                <Map mapId="rechercheMap" style={showMap ? mapStyleSmall : mapStyleLarge} showMap={showMap}/>
            </div>
        </Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default connect(
  ({
    searchReducer: { currentLocation, filteredNurseries, isGoogleMapsApiLoaded }
  }) => ({
    currentLocation,
    filteredNurseries,
    isGoogleMapsApiLoaded
  })
)(RechercheCreche);
