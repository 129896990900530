import React from "react";
import { useSelector } from "react-redux";

function Private({ children }) {
  const accessToken = useSelector(
    state => state.authenticationReducer.accessToken
  );

  if (accessToken) return children;
  else return null;
}

export default Private;
