import axios from "axios";
import { setUser } from "./userActions";
import jwt from "jsonwebtoken";

export const setAccessToken = token => ({
  type: "SET_ACCESS_TOKEN",
  token
});

export const deleteAccessToken = () => ({
  type: "DELETE_ACCESS_TOKEN"
});

export const refreshToken = () => {
  return dispatch => {
    axios
      .get("/authentication/refresh_token", {
        withCredentials: true
      })
      .then(({ data }) => {
        const { iat, exp } = jwt.decode(data.accessToken);
        dispatch(setAccessToken(data.accessToken));
        dispatch(setUser());
        setTimeout(() => dispatch(refreshToken()), (exp - iat) * 1000 - 2000);
      })
      .catch(err => {
        dispatch(deleteAccessToken());
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(deleteAccessToken());
    axios.post(`/authentication/logout`, null, {
      withCredentials: true
    });
  };
};
