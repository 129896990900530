import React, { Fragment, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./Header.css";
import { Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/authenticationActions";
import Private from "./Private";
import Public from "./Public";
import { LinkContainer } from "react-router-bootstrap";

function Header() {
  const user = useSelector(state => state.userReducer.user);
  const dispatch = useDispatch();

  const history = useHistory();

  return (
    <Fragment>
      <Navbar className="bg_purple_light shadow" expand="lg">
        <NavLink to={"/"}>
          <Navbar.Brand></Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ justifyContent: "flex-end" }}
        >
          <Nav>
            <NavLink
              activeClassName="active"
              className="hover"
              to={"/NotreConcept"}
            >
              Notre concept
            </NavLink>
            <NavLink activeClassName="active" className="hover" to={"/Contact"}>
              Contact
            </NavLink>
            <Public>
              <NavLink className="hover" to={"/ProSignUp"}>
                Espace professionnel
              </NavLink>
            </Public>
            <Public>
              <NavLink className="hover" to={"/FamilySignUp"}>
                Espace famille
              </NavLink>
            </Public>
          </Nav>

          <Private>
            <NavDropdown
              title={"Bonjour " + (user && user.AO_PARENT_firstname)}
              id="collasible-nav-dropdown"
            >
              <LinkContainer
                to="/user"
                style={{ color: "#390153", background: "none" }}
              >
                <NavDropdown.Item>Mon profil</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer
                to="/user/reservations"
                style={{ color: "#390153", background: "none" }}
              >
                <NavDropdown.Item>Mes réservations</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer
                to="/user/dashboard"
                style={{ color: "#390153", background: "none" }}
              >
                <NavDropdown.Item>Mes crèches</NavDropdown.Item>
              </LinkContainer>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item
                style={{
                  color: "#FCFAF7 ",
                  background: "#390153",
                  margin: "0 auto"
                }}
                onClick={() => {
                  dispatch(logout());
                  history.push("/");
                }}
              >
                Déconnexion
              </NavDropdown.Item>
            </NavDropdown>
          </Private>

          <Public>
            <NavLink to={"/connexion"}>
              <Button className="bg_purple_dark nav">Connexion</Button>
            </NavLink>
          </Public>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
}

export default connect(({ userReducer: { user } }) => ({ user }))(Header);
