import React, { Component, Fragment } from 'react';
import './Style.css';
import { Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';


class Style extends Component
{
    state = {}
    render()
    {
        return (
            <Fragment>
                <h1>Ceci est un titre h1</h1>
                <h2>Ceci est un titre h2</h2>
                <h3>Ceci est un titre h3</h3>
                <h4>Ceci est un titre h4</h4>
                <p>Ceci est un paragraphe. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse dapibus orci ut consectetur mollis. Suspendisse ullamcorper tempus fringilla. Integer et posuere sapien. Vivamus lectus arcu, sollicitudin vel mauris ut, pharetra blandit turpis. Vivamus sit amet convallis quam. Fusce consequat vel nisl sit amet sodales. Nullam aliquam mi id ipsum egestas finibus.</p>
                <p>Suspendisse dapibus orci ut consectetur mollis. Suspendisse ullamcorper tempus fringilla.</p>
                <button>rounded button</button>
                <button className="border_btn">border button</button>
                <button className="full_btn">full button</button>
                <div style={{ display: 'flex' }}>
                    <div className="shadow bg_purple_dark" style={{ width: '100px', height: '100px', margin: '10px auto' }}></div>
                    <div className="shadow bg_purple_light" style={{ width: '100px', height: '100px', margin: '10px auto' }}></div>
                    <div className="shadow bg_yellow" style={{ width: '100px', height: '100px', margin: '10px auto' }}></div>
                </div>
                <Form>
                    <FormGroup controlId="formBasicEmail">
                        <FormLabel>Email address</FormLabel>
                        <FormControl type="email" placeholder="Enter email" />
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                        <FormLabel>Password</FormLabel>
                        <FormControl type="password" placeholder="Enter password" />
                    </FormGroup>
                </Form>
            </Fragment>
        );
    }
}

export default Style;
