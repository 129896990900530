import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import nicolaslorut from "../assets/nicolaslorut.jpg";
import axios from "axios";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: "",
      firstName: "",
      email: "",
      message: "",
      isChecked: false,
      emptyfield: "",
      sendOk: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkFormContact = this.checkFormContact.bind(this);
    this.sendFormContact = this.sendFormContact.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  checkFormContact() {
    const { email, firstName, lastName, message, checked } = this.state;
    if (
      email !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      message !== "" &&
      checked !== false
    ) {
      this.sendFormContact(this.state);
      this.setState({ sendOk: "Message envoyez" });
    } else {
      this.setState({ emptyfield: "Champ manquant" });
    }
  }
  sendFormContact = () => {
    const { lastName, firstName, message, isChecked, email } = this.state;
    axios
      .post(`/api/formContact/`, {
        lastName,
        firstName,
        email,
        message,
        isChecked
      })
      .then(res => {
        console.log(res.data);
      });
  };

  render() {
    const {
      lastName,
      firstName,
      emptyfield,
      email,
      message,
      sendOk
    } = this.state;
    const { handleChange, checkFormContact } = this;

    return (
      <div>
        <Header />
        <Container>
          <div className="filariane" style={{ paddingTop: "30px" }}>
            <NavLink to={"/"} style={{ color: "#9E50C2" }}>
              BABILY -{" "}
            </NavLink>
            <NavLink to={"/contact"} style={{ color: "#9E50C2" }}>
              CONTACT
            </NavLink>
          </div>
          <h1>Contact</h1>
          <Row>
            <Col
              lg="6"
              xs="12"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <img
                src={nicolaslorut}
                alt="Nicolas Lorut"
                style={{ minHeight: "350px", objectFit: "cover" }}
              ></img>
            </Col>
            <Col
              style={{
                backgroundColor: "#390153",
                padding: "20px 50px",
                minHeight: "350px"
              }}
              lg="6"
              xs="12"
            >
              <div>
                <p style={{ color: "white", fontSize: "1.2em" }}>Adresse :</p>
                <p style={{ color: "white" }}>Nicolas Lorut</p>
                <p style={{ color: "white" }}>483 105 631 RCS LYON</p>
                <p style={{ color: "white" }}>
                  61 Cours de la liberté - 69003 Lyon - France
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: "1.2em",
                    marginTop: "30px"
                  }}
                >
                  Email :
                </p>
                <p style={{ color: "white" }}>nicolas@babily.fr</p>
                <p
                  style={{
                    color: "white",
                    fontSize: "1.2em",
                    marginTop: "30px"
                  }}
                >
                  Téléphone :
                </p>
                <p style={{ color: "white" }}>+33(0) 608 970 282</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }} xs={12}>
              <Form>
                <h2
                  style={{ textAlign: "center", padding: "130px 0px 0px 0px" }}
                >
                  Formulaire de contact
                </h2>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Nom *</FormLabel>
                  <FormControl
                    type="nom"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                    placeholder="Nom"
                    className="form-control"
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Prénom *</FormLabel>
                  <FormControl
                    type="prenom"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    placeholder="Prénom"
                    className="form-control"
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Email *</FormLabel>
                  <FormControl
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="form-control"
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Message *</FormLabel>
                  <FormControl
                    as="textarea"
                    rows="5"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    placeholder="Message"
                    className="form-control"
                  ></FormControl>
                </FormGroup>
                <div
                  style={{
                    backgroundColor: "#FF4D4D",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center"
                  }}
                >
                  {emptyfield}
                </div>
                <div
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center"
                  }}
                >
                  {sendOk}
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.isChecked}
                      onChange={this.toggleChange}
                    />{" "}
                    J’ai lu et accepté les CGU.
                  </label>
                </div>
                <Button
                  className="button"
                  style={{ margin: "60px auto 100px auto", display: "block" }}
                  onClick={checkFormContact}
                >
                  Envoyer
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
export default Contact;
