const initialState = {
	AO_BOSS_user: 0,
	AO_EAJE: [{}],
	currentNurseryId: null
};


const connectReducer = (state = initialState, action) =>
{
	switch (action.type) {
		case "SET_CURRENT_BOSS_USER":
			return {
				...state,
				AO_BOSS_user: action.AO_BOSS_user
			};
		case "SET_CURRENT_NURSERY":
			//console.log('current nursery :', action.currentNurseryId)
			return {
				...state,
				currentNurseryId: action.currentNurseryId
			};
		case "SET_CURRENT_NURSERIES":
			return {
				...state,
				AO_EAJE: action.AO_EAJE

			};

		default:
			return state;
	}
};

export default connectReducer;
