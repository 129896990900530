import React, { useState } from "react";
import DistanceFilter from "./DistanceFilter";
import DatePicker from "./DatePicker";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

function Filters({ dateTimeFilter }) {
    const [displayDateTimePicker, toggleDisplay] = useState(false);
    return (
        <>
            <Col md={{ span: 4, offset: 0 }} xs={{ span: 4, offset: 2 }}>
                <DistanceFilter />
            </Col>
            <Col md="8" xs="6">
                <button onClick={toggleDisplay}>
                    {dateTimeFilter
                        ? `${dateTimeFilter.startDate.getDate()}-${dateTimeFilter.startDate.getMonth() + 1} | ${dateTimeFilter.startDate.getHours()}:${dateTimeFilter.startDate.getMinutes() ||
                              "00"}-${dateTimeFilter.endDate.getHours()}:${dateTimeFilter.endDate.getMinutes() || "00"}`
                        : "Créneaux"}
                </button>
                {displayDateTimePicker && <DatePicker toggleDisplay={toggleDisplay} />}
            </Col>
        </>
    );
}

export default connect(({ searchReducer: { dateTimeFilter } }) => ({
    dateTimeFilter
}))(Filters);
