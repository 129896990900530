import React, { Component, useState } from "react";
import { connect } from "react-redux";
import "./Map.css";
import { Link } from "react-router-dom";

class Map extends Component {
    constructor(props) {
        super(props);
        this.markers = [];
        this.state = {
            selectedMarker: null,
            width: 0
        };
        window.addEventListener("resize", this.update);
    }

    changeZoomLevel = () => {
        const { distanceFilter, nursery } = this.props;
        if (this.map && distanceFilter >= 25000) {
            this.map.setZoom(10);
        } else if (distanceFilter >= 10000 && distanceFilter < 25000) {
            this.map.setZoom(12);
        } else if (distanceFilter >= 5000 && distanceFilter < 10000) {
            this.map.setZoom(13);
        } else if (distanceFilter >= 2000 && distanceFilter < 5000) {
            this.map.setZoom(14);
        } else if (distanceFilter >= 1000 && distanceFilter < 2000) {
            this.map.setZoom(15);
        } else if (distanceFilter >= 500 && distanceFilter < 1000) {
            this.map.setZoom(16);
        } else {
            this.map.setZoom(13);
        }
        if (nursery) {
            this.map.setZoom(14);
        }
    };

    generateMarker = () => {
        const { nursery } = this.props;
        const marker = new window.google.maps.Marker({
            position: {
                lat: nursery.AO_EAJE_latitude,
                lng: nursery.AO_EAJE_longitude
            },
            map: this.map
        });
    };

    generateMarkers = () => {
        const { currentLocation, filteredNurseries, selectedNuseryLat, selectedNuseryLong } = this.props;

        if (this.currentLocationMarker) this.currentLocationMarker.setMap(null);
        this.currentLocationMarker = new window.google.maps.Marker({
            position: {
                lat: currentLocation.lat,
                lng: currentLocation.lng
            },
            map: this.map,
            icon: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
            title: `adresse selectionnée : ${currentLocation.address}`
        });

        if (this.map) {
            this.map.setCenter({
                lat: currentLocation.lat,
                lng: currentLocation.lng
            });
        }
        if (filteredNurseries) {
            if (this.markers.length > 0) {
                this.markers.forEach(marker => {
                    marker.setMap(null);
                });
            }
            this.markers = [];
            filteredNurseries.forEach(nursery => {
                const marker = new window.google.maps.Marker({
                    position: {
                        lat: nursery.AO_EAJE_latitude,
                        lng: nursery.AO_EAJE_longitude
                    },
                    map: this.map
                });

                marker.addListener("click", () => {
                    this.isClicked = true;
                    this.setState({
                        selectedMarker: nursery
                    });
                });
                this.markers.push(marker);
            });
        }
    };

    componentDidMount() {
        const { currentLocation, mapId, nursery } = this.props;
        this.update();
        this.map = new window.google.maps.Map(document.getElementById(mapId), {
            center: { lat: nursery ? nursery.AO_EAJE_latitude : currentLocation.lat, lng: nursery ? nursery.AO_EAJE_longitude : currentLocation.lng }
        });
        this.map.addListener("click", () => {
            this.setState({ selectedMarker: null });
        });

        if (nursery) {
            this.generateMarker();
        } else {
            this.generateMarkers();
        }
        this.changeZoomLevel();
    }

    update = () => {
        this.setState({
            width: window.innerWidth
        });
    };

    render() {
        this.map && this.changeZoomLevel();
        const { selectedMarker } = this.state;
        const { mapId, nursery, showMap } = this.props;
        const markerHasBeenClicked = selectedMarker ? "mapSlideLeft" : "mapSlideRight";
        const start = !this.isClicked ? "static" : markerHasBeenClicked;
        if (nursery) {
            this.generateMarker();
        } else {
            this.generateMarkers();
        }

        return (
            <div className={start} style={this.props.style}>
                <div
                    id={mapId}
                    style={{
                        height: "100%",
                        width: "100%"
                    }}
                ></div>

                <div
                    className={selectedMarker ? "modalSlideIn" : "modalSlideOut"}
                    style={{
                        position: "fixed",
                        top: 0,
                        width: showMap && selectedMarker ? "100vw" : "30vw",
                        height: "100vh",
                        backgroundColor: "white",
                        padding: "230px 50px 0px 50px",
                        boxShadow: "10px 0px 25px rgba(172, 172, 172, 0.2)",
                        display: !this.isClicked && "none"
                    }}
                >
                    {selectedMarker && (
                        <div>
                            <h1 style={{ fontSize: this.state.width < 991 ? "1.6em" : "2em" }}>{selectedMarker.AO_EAJE_name}</h1>
                            <p>Ouverture : {selectedMarker.AO_EAJE_opening.substring(0, selectedMarker.AO_EAJE_opening.length - 3)}</p>
                            <p>Fermeture : {selectedMarker.AO_EAJE_closing.substring(0, selectedMarker.AO_EAJE_closing.length - 3)}</p>
                            <br></br>
                            <p style={{ color: "#390153" }}>{selectedMarker.AO_EAJE_address}</p>
                            <p style={{ color: "#390153" }}>{selectedMarker.AO_EAJE_zip}</p>
                            <p style={{ color: "#390153" }}>{selectedMarker.AO_EAJE_city}</p>
                            <br></br>
                            <Link to={"/creche/" + selectedMarker.AO_EAJE_id}>
                                <button style={{ backgroundColor: "#FCD260", color: "white" }}>Détails</button>
                            </Link>
                            <button style={{ position: "absolute", top: 90, right: 30, backgroundColor: "#9E50C2", color: "white" }} onClick={() => this.setState({ selectedMarker: null })}>
                                Fermer
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(({ searchReducer: { filteredNurseries, isGoogleMapsApiLoaded, currentLocation, distanceFilter } }) => ({
    filteredNurseries,
    isGoogleMapsApiLoaded,
    currentLocation,
    distanceFilter
}))(Map);
