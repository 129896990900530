import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import { Form, InputGroup, Button } from "react-bootstrap";
import { setDateTimeFilter, filterByDistance } from "../actions/searchActions";
import "./DatePicker.css";

function DatePicker({ dispatch, toggleDisplay }) {
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDisplay();
            }
        }

        useEffect(() => {
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    let date = new Date(Date.now());
    const startTime = { hours: date.getHours() + 1, minutes: 0 };
    const endTime = { hours: date.getHours() + 2, minutes: 0 };
    return (
        <div ref={wrapperRef} className="p-2 datePicker" >
            <Calendar
                value={date}
                onChange={day => {
                    date = day;
                }}
                className="calendar"
            />

            <InputGroup className="mt-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>Heure de début</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" defaultValue={date.getHours() + 1} onChange={event => (startTime.hours = parseInt(event.target.value))}>
                    {hours.map(hour => (
                        <option value={hour} key={hour}>
                            {hour}
                        </option>
                    ))}
                </Form.Control>
                <Form.Control as="select" defaultValue={0} onChange={event => (startTime.minutes = parseInt(event.target.value))}>
                    {minutes.map(minute => (
                        <option value={minute} key={minute}>
                            {minute === 0 ? "00" : minute}
                        </option>
                    ))}
                </Form.Control>
            </InputGroup>

            <InputGroup className="mt-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>Heure de fin</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control as="select" defaultValue={date.getHours() + 2} onChange={event => (endTime.hours = parseInt(event.target.value))}>
                    {hours.map(hour => (
                        <option value={hour} key={hour}>
                            {hour}
                        </option>
                    ))}
                </Form.Control>
                <Form.Control as="select" defaultValue={date.getMinutes()} onChange={event => (endTime.minutes = parseInt(event.target.value))}>
                    {minutes.map(minute => (
                        <option value={minute} key={minute}>
                            {minute === 0 ? "00" : minute}
                        </option>
                    ))}
                </Form.Control>
            </InputGroup>

            <button
                className="mt-2 mr-2"
                onClick={() => {
                    dispatch(setDateTimeFilter(date, startTime, endTime));
                    dispatch(filterByDistance());
                    toggleDisplay();
                }}
            >
                Valider
            </button>
            <button
                className="mt-2"
                onClick={() => {
                    dispatch(setDateTimeFilter(null));
                    dispatch(filterByDistance());
                    toggleDisplay();
                }}
            >
                Effacer
            </button>
        </div>
    );
}

export default connect()(DatePicker);

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const minutes = [0, 15, 30, 45];
