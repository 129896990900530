const initialState = {
  accessToken: null
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      return { ...state, accessToken: action.token };
    case "DELETE_ACCESS_TOKEN":
      return { ...state, accessToken: null };
    default:
      return state;
  }
};

export default authenticationReducer;
