import React, { useState, Fragment } from "react";
import
{
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button,
  Row,
  Col,
  Container,
  InputGroup
} from "react-bootstrap";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import uuid from "uuid/v1";
import logoDefault from "../assets/logo_default.png";
import ProTeamTestimony from "../components/ProTeamTestimony";
import ProHours from "../components/ProHours";
import ProResa from "../components/ProResa";
import { host } from "../conf";


let file = null;

const ProPreferencesCreche = ({ currentNurseryId }) =>
{
  const [values, setValues] = useState({
    AO_EAJE_id: currentNurseryId,
    AO_EAJE_name: "",
    AO_EAJE_email: "",
    AO_EAJE_theme: "",
    AO_EAJE_phone: "",
    AO_EAJE_address: "",
    AO_EAJE_zip: 0,
    AO_EAJE_city: "",
    // AO_EAJE_latitude: '', // ?
    // AO_EAJE_longitude: '', // ?
    AO_EAJE_opening: "00:00",
    AO_EAJE_closing: "00:00",
    AO_EAJE_noon: "00:00",
    AO_EAJE_afternoon: "00:00",
    AO_EAJE_capacity: "",
    AO_EAJE_dailyAgreement: 0,
    AO_EAJE_minBookDuration: "00:00",
    AO_EAJE_minBookExtent: "00:00",
    AO_EAJE_partialBooking: false, // Réservation partielle possible
    AO_NOAD_start: "00:00", //Réservation partielle possible, début
    AO_NOAD_end: "00:00", //Réservation partielle possible, fin
    AO_NOAD_noa: false, // Pas d'arrivée sur cette plage
    AO_NOAD_nod: false, // Pas de départ sur cette plage"
    AO_EAJE_bookDelay: "00:00",
    AO_EAJE_bookLimit: "00:00",
    AO_EAJE_bookComment: "",
    AO_EAJE_history: "",
    AO_EAJE_project: "",
    AO_EAJE_team_text: '',
    AO_TEAM_name: "",
    AO_TEAM_lastname: "",
    AO_TEAM_role: "",
    AO_TESTIMONY_id: "",
    AO_TESTIMONY_text: "",
    AO_TESTIMONY_name: "",
    AO_TESTIMONY_lastname: "",
  });

  const [modif, setModif] = useState(false);
  const remonterLaModif = () => setModif(!modif)
  const updateFields = event =>
  {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event =>
  {
    event.preventDefault();
    axios
      .all([
        axios.post(`/pro/nursery/${currentNurseryId}/preferences`, values),
        axios.post(
          `/pro/nursery/${currentNurseryId}/preferences/reservation`,
          values
        ),
        axios.post(
          `/pro/nursery/${currentNurseryId}/preferences/equipe`,
          values
        ),
        axios.put(
          "/pro/temoignage/" + values.AO_TESTIMONY_id,
          values
        )
      ])
      .then(
        axios.spread((r1, r2, r3, r4) =>
        {
          console.log(r1.data, r2.data, r3.data, r4.data);
        })
      );
  };

  useEffect(() =>
  {
    axios
      .all([
        axios.get(`/pro/nursery/${currentNurseryId}/preferences`),
        axios.get(`/pro/nursery/${currentNurseryId}/preferences/reservation`),
        axios.get(`/pro/nursery/${currentNurseryId}/preferences/equipe`),
        axios.get(`/pro/nursery/${currentNurseryId}/preferences/temoignage`),
        axios.get(`/pro/nursery/${currentNurseryId}/preferences/pictures`)
      ])
      .then(
        axios.spread((r1, r2, r3, r4, r5) =>
        {
          setValues({
            ...values,
            ...r1.data[0],
            ...r2.data[0],
            ...r3.data[0],
            ...r4.data
          });
          setTestimonies(r4.data)
          setSrcs(
            r5.data
          )
        })
      );
  }, [currentNurseryId, modif]);

  const [src, setSrc] = useState(false);
  const [srcs, setSrcs] = useState([]);
  const [galeryImages, setGaleryImages] = useState([]);
  const [testimonies, setTestimonies] = useState([]);

  const changePicture = event =>
  {
    file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = event =>
    {
      setSrc(event.target.result);
    };
  };

  const onClickPicture = () =>
  {
    const data = new FormData();
    const fileName = uuid() + "." + file.name.split(".").pop();
    data.append("logo", file, fileName);
    axios.post(`/pro/nursery/${currentNurseryId}/preferences/logo`, data);
  };

  const changePictures = event =>
  {
    setGaleryImages(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = event =>
      {
        setSrcs([...srcs, event.target.result]);
      };
    }
  };

  const onClickPictures = event =>
  {
    const data = new FormData();
    for (let i = 0; i < galeryImages.length; i++)
      data.append(i, galeryImages[i]);
    axios.post(`/pro/nursery/${currentNurseryId}/preferences/pictures`, data);
  };

  return (
    <Fragment key={values.AO_EAJE_id}>
      <Header />
      <Container style={{ padding: "30px 0 200px 0" }}>
        <div className="filariane">
          <NavLink style={{ color: "#9E50C2" }} to={"/"}>BABILY - </NavLink>
          <NavLink style={{ color: "#9E50C2" }} to={"/espacePro"}>ESPACE PROFESSIONNEL - </NavLink>
          <NavLink style={{ color: "#9E50C2", textTransform: 'uppercase' }} to={"/espacePro/preferences"}>préférences</NavLink>
        </div>
        <h1 style={{ textAlign: "center" }}>Vos préférences</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={{ span: 6 }} sm={{ span: 12 }}>
              {/********** Coordonnées  */}
              <div className="cadre">
                <h2>Coordonnées de l'établissement</h2>
                <FormGroup>
                  <FormLabel>Nom de l'établissement</FormLabel>
                  <FormControl
                    onChange={updateFields}
                    name="AO_EAJE_name"
                    type="text"
                    value={values.AO_EAJE_name}
                    placeholder="Nom"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Adresse email</FormLabel>
                  <FormControl
                    onChange={updateFields}
                    value={values.AO_EAJE_email}
                    name="AO_EAJE_email"
                    type="email"
                    placeholder="monadresse@email.com"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Numéro de téléphone</FormLabel>
                  <FormControl
                    onChange={updateFields}
                    value={values.AO_EAJE_phone}
                    name="AO_EAJE_phone"
                    type="txt"
                    placeholder="Numéro de téléphone"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Adresse de l'établissement</FormLabel>
                  <FormControl
                    onChange={updateFields}
                    value={values.AO_EAJE_address}
                    name="AO_EAJE_address"
                    type="txt"
                    placeholder="Adresse de l'établissement"
                  />
                </FormGroup>
                <Form.Group controlId="formGridZip">
                  <Form.Label>Code postal</Form.Label>
                  <Form.Control
                    onChange={updateFields}
                    value={values.AO_EAJE_zip}
                    name="AO_EAJE_zip"
                  />
                </Form.Group>
                <Form.Group controlId="formGridCity">
                  <Form.Label>Ville</Form.Label>
                  <Form.Control
                    onChange={updateFields}
                    value={values.AO_EAJE_city}
                    name="AO_EAJE_city"
                  />
                </Form.Group>
              </div>
              {/********** Personnalisation */}
              <div className="cadre" style={{ marginTop: '30px' }}>
                <h2>Un brin de personnalisation :</h2>
                <FormGroup>
                  <FormLabel htmlFor="basic-url">
                    Adresse de votre espace Familles
                  </FormLabel>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="shadow"
                        style={{ backgroundColor: "white", border: "0" }}
                        id="basic-addon"
                      >
                        https://
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={updateFields}
                      value={values.AO_EAJE_theme}
                      name="AAO_EAJE_theme"
                      type="text"
                      placeholder="Adresse de votre espace Familles"
                    />
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        style={{
                          backgroundColor: "white",
                          border: "0",
                          zIndex: "1"
                        }}
                        id="basic-addon1"
                      >
                        babily.fr
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <FormLabel>Logo de ma crèche</FormLabel>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <img
                      style={{
                        margin: "5px 25px 5px 5px",
                        width: "100px",
                        height: "100px",
                        objectFit: "cover"
                      }}
                      src={src ? logoDefault : `${host}/pro/nursery/${values.AO_EAJE_id}/preferences/logo`}
                      alt="branding"
                    ></img>
                    <FormControl
                      onChange={changePicture}
                      name="AO_EAJE_logo"
                      type="file"
                    />
                  </div>
                </FormGroup>
                <Button className="button" onClick={onClickPicture}>
                  Télécharger
                </Button>
              </div>
            </Col>
            <Col md={{ span: 6 }} sm={{ span: 12 }}>
              {/********** Horaires  */}
              <ProHours updateFields={updateFields} values={values} />
              {/********** Réservations  */}
              <ProResa updateFields={updateFields} values={values} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 12 }}>
              {/********** Informations crèches  */}
              <div className="cadre" style={{ marginTop: '30px' }}>
                <h2>Informations sur ma crèche</h2>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Histoire</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    onChange={updateFields}
                    value={values.AO_EAJE_history}
                    name="AO_EAJE_history"
                    type="text"
                    placeholder="ex: Un petit mot sur ma crèche"
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Projet pédagogique</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    onChange={updateFields}
                    value={values.AO_EAJE_project}
                    name="AO_EAJE_project"
                    type="text"
                    placeholder="ex: Projet pédagogique"
                  />
                </Form.Group>
                <Form.Group>
                  <FormLabel>Galerie photo de ma crèche</FormLabel>
                  <Row>
                    {srcs.length < 0 ? (
                      <img
                        style={{
                          margin: "5px 25px 5px 5px",
                          width: "100px",
                          height: "100px",
                          objectFit: "cover"
                        }}
                        src={logoDefault}
                        alt="gallery"
                      ></img>
                    ) : (srcs.map((s, i) => (
                      <Col md={4}>
                        <img
                          key={`srcs-${i}`}
                          style={{
                            margin: "5px 25px 5px 5px",
                            width: "150px",
                            height: "150px",
                            objectFit: "cover"
                          }}
                          src={`${host}/pro/images/${s.AO_EAJE_pictures_eaje}/gallery/${s.AO_EAJE_pictures_name}`}
                          alt="gallery"
                        ></img>
                      </Col>
                    ))
                      )}
                  </Row>
                  <FormControl
                    onChange={changePictures}
                    name="AO_EAJE_picture"
                    type="file"
                    multiple
                  />

                </Form.Group>
                <Button className="button" onClick={onClickPictures}>
                  Télécharger
                </Button>
              </div>
              <ProTeamTestimony updateFields={updateFields} values={values} testimonies={testimonies} remonterLaModif={remonterLaModif} />
              <Button
                style={{ display: "block", margin: "50px auto 0 auto" }}
                className="button"
                type="submit"
              >
                Enregistrer mes modifications
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default connect(state => ({
  currentNurseryId: state.connectReducer.currentNurseryId
}))(ProPreferencesCreche);
