import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Map from "../components/Map";
import Slots from "../components/Slots";
import Temoignages from "../components/Temoignages";
import apostrophe from "../assets/testimony.png";
import { host } from "../conf";
import "../components/Creche.css";

const mapStyleCreche = {
  position: "relative",
  top: 0,
  height: "380px",
  left: 0,
  width: "100%"
};

const Creche = props => {
  const [nursery, setNursery] = useState();
  const [team, setTeam] = useState([]);
  const [testimony, setTestimony] = useState([]);
  const [gallery, setGallery] = useState([]);
  // const [picturesTeam, setPicturesTeam] = useState([])

  let { id } = useParams();

  useEffect(() => {
    axios
      .all([
        axios.get("/pro/nursery/" + id + "/preferences"),
        axios.get("/pro/nursery/" + id + "/preferences/equipe"),
        axios.get("/pro/nursery/" + id + "/preferences/temoignage"),
        axios.get("/pro/nursery/" + id + "/preferences/pictures")
      ])
      .then(
        axios.spread((r1, r2, r3, r4) => {
          setNursery({
            ...nursery,
            ...r1.data[0]
          });
          setTeam(r2.data);
          setTestimony(r3.data);
          setGallery(r4.data);
          // setPicturesTeam(r5.data);
        })
      )
      .catch(e => console.error(e));
  }, []);

  return (
    <div className="bg_cream">
      <Header />
      {nursery && (
        <Fragment>
          <Container style={{ marginTop: "30px" }}>
            <div className="filariane">
              <NavLink to={"/"} style={{ color: "#9E50C2" }}>
                BABILY -{" "}
              </NavLink>
              <NavLink to={"/rechercheCreche"} style={{ color: "#9E50C2" }}>
                TROUVER UNE CRECHE -{" "}
              </NavLink>
              <NavLink
                to={`/creche/${nursery.AO_EAJE_id}`}
                style={{ textTransform: "uppercase", color: "#9E50C2" }}
              >{`CRECHE ${nursery.AO_EAJE_name}`}</NavLink>
            </div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px 0 20px 0",
                borderBottom: "2px solid #9e50c2"
              }}
            >
              <Col lg="1" sm="12">
                {nursery.AO_EAJE_logo && (
                  <img
                    className="branding"
                    src={`${host}/pro/nursery/${nursery.AO_EAJE_id}/preferences/logo`}
                    alt={nursery.AO_EAJE_name}
                  ></img>
                )}
              </Col>
              <Col lg="7" sm="12">
                <h1 style={{ marginBottom: "0" }}>{nursery.AO_EAJE_name}</h1>
              </Col>
              <Col
                lg="4"
                sm="12"
                className="buttons_creche"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <a
                  className="border_btn"
                  href={`mailto:${nursery.AO_EAJE_senderEmail}`}
                >
                  Contacter
                </a>
                <button className="full_btn">Se pré-inscrire</button>
              </Col>
            </Row>
          </Container>
          <Row style={{ width: "100vw" }}>
            <Col>
              <Slots nurseryId={id} />
            </Col>
          </Row>
          <Row noGutters={true} style={{ width: "100%", marginBottom: "50px" }}>
            <Col
              style={{
                backgroundColor: "#390153",
                padding: "50px 50px 50px 10vw",
                margin: "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
              lg="6"
              sm="12"
              xs="12"
            >
              <h2 style={{ color: "white" }}>{nursery.AO_EAJE_name}</h2>
              <h2
                style={{
                  color: "#FCD260",
                  marginBottom: "20px",
                  fontSize: "1.2em"
                }}
              >
                CONTACT
              </h2>
              <Row>
                <Col md={6} xs={12}>
                  <p style={{ color: "white" }}>
                    {nursery.AO_EAJE_address} <br /> {nursery.AO_EAJE_zip}{" "}
                    {nursery.AO_EAJE_city}{" "}
                  </p>
                  <p style={{ color: "white" }}>
                    Téléphone : {nursery.AO_EAJE_phone}
                  </p>
                  <p style={{ color: "white" }}>
                    Email : {nursery.AO_EAJE_email}
                  </p>
                </Col>

                <Col md={6} xs={12}>
                  <p style={{ color: "white" }}>
                    Ouverture :{" "}
                    {nursery.AO_EAJE_opening.substring(
                      0,
                      nursery.AO_EAJE_opening.length - 3
                    )}
                  </p>
                  <p style={{ color: "white" }}>
                    Fermeture :{" "}
                    {nursery.AO_EAJE_closing.substring(
                      0,
                      nursery.AO_EAJE_closing.length - 3
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg="6" sm="12" xs="12">
              <Map style={mapStyleCreche} nursery={nursery} mapId="crecheMap" />
            </Col>
          </Row>

          <Container>
            {nursery.AO_EAJE_history ? (
              <Row style={{ margin: "8vw 0 4vw 0" }}>
                <Col md={{ span: 8, offset: 2 }} sm={{ span: 12 }}>
                  <h2>HISTOIRE</h2>
                  <p>{nursery.AO_EAJE_history}</p>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {nursery.AO_EAJE_project ? (
              <Row style={{ margin: "4vw 0 8vw 0" }}>
                <Col md={{ span: 8, offset: 2 }} sm={{ span: 12 }}>
                  <h2>PROJET PÉDAGOGIQUE</h2>
                  <p> {nursery.AO_EAJE_project}</p>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row style={{ margin: "8vw 0" }}>
              {gallery &&
                gallery.map((picture, i) => (
                  <Col key={i + "_picture"} lg="4" sm="12">
                    <img
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        paddingBottom: "20px"
                      }}
                      src={`${host}/pro/images/${picture.AO_EAJE_pictures_eaje}/gallery/${picture.AO_EAJE_pictures_name}`}
                      alt={nursery.AO_EAJE_name}
                    ></img>
                  </Col>
                ))}
            </Row>
          </Container>
          <div className="bg_team" style={{ paddingBottom: "8vw" }}>
            <Container>
              <Row style={{ padding: "5vw 0 0 0" }}>
                {nursery.AO_EAJE_team_text ? (
                  <Col lg={5} md={{ span: 12 }}>
                    <h2>L’ÉQUIPE</h2>
                    <p>{nursery.AO_EAJE_team_text}</p>
                  </Col>
                ) : (
                  ""
                )}
                <Col lg={{ span: 6, offset: 1 }} md={{ span: 12 }}>
                  <Row>
                    {team.map((t, i) => (
                      <Col
                        key={i + "_team"}
                        lg="4"
                        sm="12"
                        xs="12"
                        style={{ marginTop: "50px", textAlign: "center" }}
                      >
                        <img
                          style={{
                            objectFit: "cover",
                            borderRadius: "50%",
                            margin: "0 auto 20px auto"
                          }}
                          src={`${host}/pro/images/team/${t.AO_TEAM_picture}`}
                          alt={`${t.AO_TEAM_name} ${t.AO_TEAM_lastname}`}
                        ></img>
                        <p style={{ color: "#390153", fontWeight: "bold" }}>
                          {t.AO_TEAM_name} {t.AO_TEAM_lastname}
                        </p>
                        <p style={{ color: "#390153" }}>{t.AO_TEAM_role}</p>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          {testimony.length > 0 && (
            <div className="bg_yellow" style={{ position: "relative" }}>
              <img
                style={{
                  position: "absolute",
                  top: "-180px",
                  left: "50%",
                  margin: "0 auto",
                  transform: "translateX(-50%)"
                }}
                src={apostrophe}
              ></img>
              <Container style={{ padding: "8vw 0" }}>
                <Row>
                  <Col>
                    <h1 style={{ textAlign: "center", marginBottom: "100px" }}>
                      TÉMOIGNAGES
                    </h1>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "self-start"
                  }}
                >
                  {testimony.map((n, i) => (
                    <Temoignages key={i + "_testimony"} n={n} />
                  ))}
                </Row>
              </Container>
            </div>
          )}
        </Fragment>
      )}
      <Footer />
    </div>
  );
};

export default Creche;
