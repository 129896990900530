import React, { useState } from "react";
import {
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect } from "react-router-dom";

const ProConnect = ({ dispatch }) => {
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);

  const [redirectDB, setRedirectDB] = useState(false);
  const [redirectProSignUp, setRedirectProSignUp] = useState(false);

  const connectPanel = {
    borderRadius: "20px",
    width: "100%",
    padding: "20px"
  };

  const clickToConnect = (email, password) => {
    axios
      .post("/pro/login/", {
        email: email,
        password: password
      })
      .then(response1 => {
        dispatch({
          type: "SET_CURRENT_BOSS_USER",
          AO_BOSS_user: response1.data.AO_USER[0].AO_USER_id
        });

        axios
          .get(`/pro/user/${response1.data.AO_USER[0].AO_USER_id}/nurseries`)
          .then(response2 => {
            const nurseries = response2.data;
            if (nurseries != []) {
              setRedirectDB(true);
            }

            axios.post(`/pro/nurseries/`, { nurseries }).then(response3 => {
              dispatch({
                type: "SET_CURRENT_NURSERIES",
                AO_EAJE: response3.data
              });
              dispatch({
                type: "SET_CURRENT_NURSERY",
                currentNurseryId: response3.data[0].AO_EAJE_id
              });
              setRedirectDB(true);
            });
          });
      });
  };

  if (redirectDB) {
    return <Redirect to="/espacePro" />;
  }
  if (redirectProSignUp) {
    return <Redirect to="/proSignUp" />;
  }

  return (
    <Card className="bg_purple_dark form_pro" style={connectPanel}>
      <Card.Body>
        <Form
          onSubmit={event => {
            event.preventDefault();
            clickToConnect(user, password);
          }}
        >
          <FormGroup controlId="formBasicEmail">
            <FormLabel>Votre adresse email</FormLabel>
            <FormControl
              type="email"
              placeholder="Email"
              onChange={e => {
                setUser(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup controlId="formBasicPassword">
            <FormLabel>Votre mot de passe</FormLabel>
            <FormControl
              type="password"
              placeholder="Mot de passe"
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </FormGroup>
          <div className="form_pro_btn">
            <Button
              style={{
                backgroundColor: "transparent",
                textDecoration: "underline",
                padding: "0",
                boxShadow: "0 0 0 0 rgba(255, 255, 255, 0.1)"
              }}
              onClick={() => setRedirectProSignUp(true)}
            >
              Pas encore inscrit ?
            </Button>
            <Button type="submit" className="button">
              Se connecter
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default connect(state => ({
  AO_BOSS_user: state.connectReducer.AO_BOSS_user,
  AO_EAJE: state.connectReducer.AO_EAJE
}))(ProConnect);
