import axios from "axios";

export const getChildren = parentid => {
    return (dispatch, getState) => {
        axios.get(`/api/parents/${parentid}/children`).then(({ data }) => {
            dispatch({ type: "SET_CHILDREN", children: data });
        });
    };
};

