import React, { Fragment } from 'react';
import { FormGroup, FormLabel, FormControl, Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from "axios";


const ProTeamTestimony = ({ updateFields, values, testimonies, remonterLaModif }) => {

    const addTestimony = () => {
        axios.post(`/pro/nursery/${currentNurseryId}/testimony`);
        remonterLaModif();
    }

    const currentNurseryId = useSelector(state => state.connectReducer.currentNurseryId)

    return (
        <Fragment>
            <div className='cadre' style={{ marginTop: '30px' }}>
                {/********** Témoignages  */}
                <h2 style={{ marginRight: 'auto', marginLeft: 'auto', textAlign: 'center' }}>Témoignages</h2>
                <Row>
                    {
                        testimonies.map((t) => {
                            return (
                                <Col md={6} xs={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Témoignage de {t.AO_TESTIMONY_name}</Form.Label>
                                        <Form.Control
                                            onChange={updateFields}
                                            as="textarea"
                                            rows="3"
                                            value={t.AO_TESTIMONY_text}
                                            name="AO_TESTIMONY_text"
                                            type="text"
                                            placeholder="Témoignage"
                                        />
                                    </Form.Group>
                                    <FormGroup>
                                        <FormLabel>Nom</FormLabel>
                                        <FormControl
                                            onChange={updateFields}
                                            value={t.AO_TESTIMONY_name}
                                            name="AO_TESTIMONY_name"
                                            type="text"
                                            placeholder="Nom"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Prénom</FormLabel>
                                        <FormControl
                                            onChange={updateFields}
                                            value={t.AO_TESTIMONY_lastname}
                                            name="AO_TESTIMONY_lastname"
                                            type="text"
                                            placeholder="Prénom"
                                        />
                                    </FormGroup>
                                    {/* <input type="hidden" name="AO_TESTIMONY_id" value={t.AO_TESTIMONY_id} /> */}
                                </Col>
                            )
                        })
                    }
                </Row>
                <Button
                    onClick={addTestimony}
                    style={{ margin: '20px auto 0 auto', display: 'block' }} className="button">
                    Ajouter un témoignage
                </Button>
            </div>
            <div className='cadre' style={{ marginTop: '30px' }}>
                <Row>
                    <Col>
                        {/********** Equipe  */}
                        <h2 style={{ marginRight: 'auto', marginLeft: 'auto', textAlign: 'center' }}>L'équipe</h2>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Résumé sur l'équipe</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                onChange={updateFields}
                                value={values.AO_EAJE_team_text}
                                name="AO_TEAM_text"
                                type="text"
                                placeholder="ex: Un petit mot sur l'équipe de la crèche"
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel>Nom</FormLabel>
                                    <FormControl
                                        onChange={updateFields}
                                        value={values.AO_TEAM_name}
                                        name="AO_TEAM_name"
                                        type="text"
                                        placeholder="Nom"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Prénom</FormLabel>
                                    <FormControl
                                        onChange={updateFields}
                                        value={values.AO_TEAM_lastname}
                                        name="AO_TEAM_lastname"
                                        type="text"
                                        placeholder="Prénom"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Rôle dans la crèche</FormLabel>
                                    <FormControl
                                        onChange={updateFields}
                                        value={values.AO_TEAM_role}
                                        name="AO_TEAM_role"
                                        type="text"
                                        placeholder="Rôle"
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <img></img>
                            </Col>
                        </Row>
                        <Button className="button">
                            Ajouter un membre de l'équipe
                        </Button>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

export default ProTeamTestimony;