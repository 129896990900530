import React, { useEffect } from "react";
import { connect } from "react-redux";
import ResultsList from "../components/ResultsList";
import { initializeNurseries } from "../actions/searchActions";

const ResultsContainer = ({ dispatch, filteredNurseries }) => {
  useEffect(() => {
    dispatch(initializeNurseries());
  }, []);

  return <ResultsList nurseries={filteredNurseries} />;
};

export default connect(({ searchReducer: { filteredNurseries } }) => ({
  filteredNurseries
}))(ResultsContainer);
