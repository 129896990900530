import React, { useState } from 'react';
import { Card, FormGroup, FormLabel, FormControl, Form, Button, Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux';


const ProSignUp = ({ dispatch }) =>
{

    const [values, setValues] = useState({
        AO_USER_email: '',
        AO_USER_pwd: ''
    });

    const [passwordBis, setPasswordBis] = useState('');
    const [passwordAlert, setPasswordAlert] = useState('');
    const [redirectConnect, setRedirectConnect] = useState(false)

    const [signIn, setSignIn] = useState(false)

    const [redirectDB, setRedirectDB] = useState(false)
    const [alert, setAlert] = useState('');


    const clickToConnect = (email, password) =>
    {
        axios
            .post("/pro/login/", {
                email: email,
                password: password
            })
            .then(response1 =>
            {
                dispatch({
                    type: "SET_CURRENT_BOSS_USER",
                    AO_BOSS_user: response1.data.AO_USER[0].AO_USER_id
                });

                axios
                    .get(`/pro/user/${response1.data.AO_USER[0].AO_USER_id}/nurseries`)
                    .then(response2 =>
                    {
                        const nurseries = response2.data;
                        if (nurseries != []) {
                            setRedirectDB(true);
                        }

                        axios.post(`/pro/nurseries/`, { nurseries }).then(response3 =>
                        {
                            dispatch({
                                type: "SET_CURRENT_NURSERIES",
                                AO_EAJE: response3.data
                            });
                            dispatch({
                                type: "SET_CURRENT_NURSERY",
                                currentNurseryId: response3.data[0].AO_EAJE_id
                            });
                            setRedirectDB(true);
                        });
                    });
            });
    };


    const updateField = (event) =>
    {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    };

    console.log('values :', values)
    const checkValues = () =>
    {
        if ((values.AO_USER_pwd === '') || (values.AO_USER_pwd !== passwordBis))
            return setAlert('Vos deux mots de passe ne correspondent pas.')
        else
            console.log('err :')
    }

    const handlesubmit = (event) =>
    {
        event.preventDefault();
        axios
            .post("/pro/nursery/signup", values)
            .then(() => clickToConnect(values.AO_USER_email, values.AO_USER_pwd)
            )
            .catch(err => setAlert(err.response.data))
    }
    const clickToSignIn = () =>
    {
        setSignIn(true);
    }

    const connectPanel = {
        borderRadius: '20px',
        width: '100%',
        padding: '20px'
    }
    if (redirectConnect) {
        return <Redirect to='/connexion' />;
    }
    if (redirectDB) {
        return <Redirect to='/espacePro' />;
    }
    return (
        <div className="bg_babily">
            <Header />
            <Container style={{ padding: '100px 0 150px 0' }}>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} xs={12}>
                        <Card className='bg_purple_dark form_pro' style={connectPanel}>
                            <Card.Body>
                                <h1 style={{ color: 'white', textAlign: 'center' }}>Inscription</h1>
                                <Form onSubmit={handlesubmit}>
                                    <FormGroup controlId="formBasicEmail">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl
                                            onChange={updateField}
                                            value={values.AO_USER_email}
                                            name="AO_USER_email"
                                            type="email"
                                            placeholder="Entrer votre email" />
                                    </FormGroup>
                                    <FormGroup controlId="formBasicEmail">
                                        <FormLabel>Mot de passe</FormLabel>
                                        <FormControl
                                            onChange={updateField}
                                            value={values.AO_USER_pwd}
                                            name="AO_USER_pwd"
                                            type="password"
                                            placeholder="Entrer votre mot de passe" />
                                    </FormGroup>
                                    <FormGroup controlId="formBasicEmail">
                                        <FormLabel>Mot de passe</FormLabel>
                                        <FormControl
                                            onChange={e => setPasswordBis(e.target.value)}
                                            value={passwordBis}
                                            name="passwordBis"
                                            type="password"
                                            placeholder="Entrer votre mot de passe" />
                                        <p style={{ backgroundColor: '#ff4d4d', color: 'white', marginTop: '10px', textAlign: 'center' }}>
                                            {alert}</p>

                                    </FormGroup>
                                    <div className='form_pro_btn'>
                                        <Button
                                            onClick={() => setRedirectConnect(true)}
                                            style={{ backgroundColor: 'transparent', textDecoration: 'underline', padding: '0', boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.1)' }}
                                        >Déjà inscrit?</Button>
                                        <Button
                                            className='button'
                                            type='submit'
                                            onClick={() => checkValues()}>Se connecter</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default connect(
    state => ({
        AO_BOSS_user: state.connectReducer.AO_BOSS_user,
        AO_EAJE: state.connectReducer.AO_EAJE,
    }
    ))(ProSignUp);
