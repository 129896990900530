import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import SearchBar from '../components/SearchBar';
import advizi from '../assets/partners/advizi.png';
import caf from '../assets/partners/caf.png';
import boostinLyon from '../assets/partners/boostinLyon.png';
import h7 from '../assets/partners/h7.png';
import inpi from '../assets/partners/inpi.png';
import frenchTech from '../assets/partners/french_tech.png'

class HomePage extends Component {
  render() {
    return (
        <div className="bg_babily">
          <Header/>
          <Container>
        <div className="logo_animate"></div>
            <Row>
              <Col>
              <h1 style={{ fontSize: "2.5em", textAlign: "center", paddingTop: "220px"}}>Les places en crèche.<br/>MAINTENANT</h1>
              <p style={{fontSize: "1.5em",  textAlign: "center"}}>32 982 heures proposées | 4006 encore disponibles</p>
              </Col>
            </Row>  
            <Row>
              <Col xs={12} md={10} style={{ paddingTop: "20px", margin:"0 auto"}}>
                <SearchBar/>
              </Col>  
            </Row> 
               <p style={{ textAlign: "center", paddingTop: "10px" }}>Nom d’une crèche, Commune, Quartier, Code postal, etc</p>
            <Row style={{paddingBottom: "150px", paddingTop: "20px"}}>
                <Col md={12}>
                  <h2 style={{color: "#9E50C2", textAlign: "center", paddingTop: "150px"}}>ILS NOUS SOUTIENNENT</h2>
                  <Row>
                    <Col xs={4} md={2}><img src={advizi} alt=" "></img></Col>
                    <Col xs={4} md={2}><img src={caf} alt=" "></img></Col>
                    <Col xs={4} md={2}><img src={boostinLyon} alt=" "></img></Col>
                    <Col xs={4} md={2}><img src={h7} alt=" "></img></Col>
                    <Col xs={4} md={2}><img src={inpi} alt=" "></img></Col>
                    <Col xs={4} md={2}><img src={frenchTech} alt=" "></img></Col>
                  </Row>
              </Col>  
            </Row> 
            </Container>
          <Footer />
        </div>
    );
  }
}
export default HomePage; 