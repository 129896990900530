import React, { useState, Fragment } from "react";
import axios from "axios";
import { getChildren } from "../actions/childAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import quit from "../assets/quit.png";

const ChildInfo = props => {
    const { child, parentid } = props;
    const dispatch = useDispatch();
    const [modifyChildren, setModifyChildren] = useState(false);
    const [modifyLastName, setModifyLastName] = useState();
    const [modifyFirstName, setModifyFirstName] = useState();
    const [confirmChanges, setConfirmChanges] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [deleteIsConfirmed, setDeleteIsConfirmed] = useState(false);
    const [putHasFailed, setPutHasFailed] = useState(false);
    const [deleteHasFailed, setDeleteHasFailed] = useState(false);
    const [modifyBirthDate, setModifyBirthDate] = useState();

    const sendNewChildInfo = () => {
        axios
            .put(`/api/children/me/infos?childId=${child.AO_CHILD_id}`, {
                AO_CHILD_name: modifyLastName,
                AO_CHILD_firstname: modifyFirstName,
                AO_CHILD_birth: modifyBirthDate
            })
            .then(dispatch(getChildren(parentid)))
            .catch(function(err) {
                console.log(err);
                setPutHasFailed(true);
            });
    };

    const deleteChildren = () => {
        axios
            .delete(`/api/user/children/${child.AO_CHILD_id}/delete`)
            .then(setTimeout(() => dispatch(getChildren(parentid)), 2000))
            .catch(function(err) {
                console.log(err);
                setDeleteHasFailed(true);
            });
    };

    const deleteConfirmation = () => {
        return (
            <Fragment>
                <h4>Confirmation</h4>
                <p>
                    Confirmez-vous la suppression de {child.AO_CHILD_firstname} {child.AO_CHILD_name}, né.e le {new Date(child.AO_CHILD_birth).toLocaleDateString("fr-FR")}?
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setConfirmDelete(!confirmDelete);
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "#fcfaf7"
                        }}
                    >
                        Non, Annuler
                    </button>
                    <button
                        onClick={() => {
                            deleteChildren();
                            setDeleteIsConfirmed(!deleteIsConfirmed);
                        }}
                        style={{
                            backgroundColor: "#fcd260",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Oui, je confirme
                    </button>
                </div>
            </Fragment>
        );
    };

    const confirmation = () => {
        return (
            <Fragment>
                <h4>Confirmation</h4>
                <p>Confirmez-vous bien les modifications suivantes ?</p>
                {modifyFirstName && <p>Prénom : {modifyFirstName}</p>}
                {modifyLastName && <p>Nom de famille : {modifyLastName}</p>}
                {modifyBirthDate && <p>Date de naissance : {new Date(modifyBirthDate).toLocaleDateString("fr-FR")}</p>}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setConfirmChanges(!confirmChanges);
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "#fcfaf7"
                        }}
                    >
                        Non, Annuler
                    </button>
                    <button
                        onClick={() => {
                            sendNewChildInfo();
                            setIsConfirmed(!isConfirmed);
                        }}
                        style={{
                            backgroundColor: modifyFirstName || modifyLastName || modifyBirthDate ? "#fcd260" : "grey",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Oui, je confirme
                    </button>
                </div>
            </Fragment>
        );
    };

    const confirmed = () => {
        return (
            <Fragment>
                <h4>Merci !</h4>
                <p>Les modifications souhaitées ont bien été effectuées</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setModifyChildren(!modifyChildren);
                            setConfirmChanges(!confirmChanges);
                            setIsConfirmed(!isConfirmed);
                        }}
                        style={{
                            backgroundColor: modifyFirstName || modifyLastName || modifyBirthDate ? "#fcd260" : "grey",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Fermer
                    </button>
                </div>
            </Fragment>
        );
    };

    const deleteConfirmed = () => {
        return (
            <Fragment>
                <h4>Merci !</h4>
                <p>La suppression à bien été effectuée.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setConfirmDelete(!confirmDelete);
                            setDeleteIsConfirmed(!deleteIsConfirmed);
                        }}
                        style={{
                            backgroundColor: "#fcd260",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Fermer
                    </button>
                </div>
            </Fragment>
        );
    };

    const failed = () => {
        return (
            <Fragment>
                <h4>Oups...</h4>
                <p>Une erreur est survenue. Veuillez réessayer.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setConfirmChanges(!confirmChanges);
                            setIsConfirmed(!isConfirmed);
                            setPutHasFailed(false);
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "white",
                            marginLeft: "20px"
                        }}
                    >
                        Réessayer
                    </button>
                </div>
            </Fragment>
        );
    };

    const deleteFailed = () => {
        return (
            <Fragment>
                <h4>Oups...</h4>
                <p>Une erreur est survenue. Veuillez réessayer.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            setConfirmDelete(!confirmDelete);
                            setDeleteIsConfirmed(!deleteIsConfirmed);
                            setDeleteHasFailed(false);
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "white",
                            marginLeft: "20px"
                        }}
                    >
                        Réessayer
                    </button>
                </div>
            </Fragment>
        );
    };

    const modal = () => {
        return (
            <div
                style={{
                    display: confirmChanges ? "flex" : "none",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: "5Ovw",
                        minWidth: "300px",
                        height: "350px",
                        backgroundColor: "#fcfaf7",
                        borderRadius: "50px",
                        padding: "40px"
                    }}
                >
                    {isConfirmed ? (putHasFailed ? failed() : confirmed()) : confirmation()}
                </div>
            </div>
        );
    };

    const deleteModal = () => {
        return (
            <div
                style={{
                    display: confirmDelete ? "flex" : "none",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: "5Ovw",
                        minWidth: "300px",
                        height: "350px",
                        backgroundColor: "#fcfaf7",
                        borderRadius: "50px",
                        padding: "40px"
                    }}
                >
                    {deleteIsConfirmed ? (deleteHasFailed ? deleteFailed() : deleteConfirmed()) : deleteConfirmation()}
                </div>
            </div>
        );
    };

    return (
        <div
            style={{
                width: "100%",
                margin: "20px 0px 0px 0px",
                height: "auto",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white"
            }}
        >
            <div
                style={{
                    color: "#9e50c2",
                    display: "flex",
                    justifyContent: "space-between",
                    height:"30px"
                }}
            >
                <div style={{ display: "flex" }}>
                    <h4>{child.AO_CHILD_firstname}&nbsp;</h4>
                    <p>{child.AO_CHILD_name}</p>
                </div>
                <p>né.e le {new Date(child.AO_CHILD_birth).toLocaleDateString("fr-FR")}</p>
                <div
                    style={{ backgroundColor: "transparent", cursor: "pointer", color: "white", borderRadius: "10px", display: "flex", justifyContent: "flex-end" }}
                    onClick={() => setConfirmDelete(true)}
                >
                    <img src={quit} alt="supprimer" title="supprimer" style={{ height: "20px", width: "20px" }} />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <button className="button" onClick={() => setModifyChildren(!modifyChildren)}>
                    {modifyChildren ? "Fermer" : "Modifier"}
                </button>
            </div>
            <Fragment>{deleteModal()}</Fragment>

            {modifyChildren && (
                <div
                    style={{
                        height: "auto",
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "column", 
                        zIndex:100
                    }}
                >
                    <div>
                        <input
                            type="text"
                            id="autocomplete1"
                            className="form-control"
                            style={{
                                width: "100%",
                                borderRadius: "50px",
                                marginBottom: "10px"
                            }}
                            placeholder="Modifier le prénom"
                            onChange={event => setModifyFirstName(event.target.value)}
                        />
                        <input
                            type="text"
                            id="autocomplete2"
                            className="form-control"
                            style={{
                                width: "100%",
                                borderRadius: "50px",
                                marginBottom: "10px"
                            }}
                            placeholder="Modifier le nom de famille"
                            onChange={event => setModifyLastName(event.target.value)}
                        />
                        <input
                            type="date"
                            id="autocomplete3"
                            className="form-control"
                            style={{
                                width: "100%",
                                borderRadius: "50px",
                                marginBottom: "10px"
                            }}
                            placeholder="Modifier la date de naissance"
                            onChange={event => setModifyBirthDate(event.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            style={{
                                backgroundColor: modifyFirstName || modifyLastName || modifyBirthDate ? "#fcd260" : "grey",
                                color: modifyFirstName || modifyLastName || modifyBirthDate ? "white" : "lightgrey",
                                cursor: modifyFirstName || modifyLastName || modifyBirthDate ? "pointer" : "auto",
                                borderRadius: "40px",
                                padding: "10px 20px",
                                fontLast: "poppinsmedium",
                                letterSpacing: "0.05em",
                                fontSize: "0.8em",
                                border: 0
                            }}
                            onClick={() => (modifyFirstName || modifyLastName || modifyBirthDate ? setConfirmChanges(true) : "")}
                        >
                            Valider
                        </button>
                    </div>
                    <Fragment>{modal()}</Fragment>
                </div>
            )}
        </div>
    );
};

export default connect(({ authenticationReducer: { accessToken } }) => ({
    accessToken
}))(ChildInfo);
