import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { getUserBookings } from "../actions/userActions";
import { connect } from "react-redux";

const ResaParent = props => {
    const { r, dispatch, user } = props;
    const [cancelledBooking, setCancelledBooking] = useState(false);
    const [isModalOpened, setModal] = useState(false);

    const cancelBooking = () => {
        axios
        .delete(
            `/resa/parents/${user.AO_PARENT_id}/children/${r.AO_CHILD_id}/resas/${r.AO_SLOT_id}`
        )
        .then(() => {
            dispatch(getUserBookings());
        })
        .catch(function(erreur) {
            console.log(erreur);
        });
    };
    console.log("r", r);

    return (
        <div>
            <Row>
                <Col lg="1" />
                <Col lg="10">
                <div>
                    <div className="cadre2" style={{ display:"flex", flexWrap:"wrap", justifyContent:"space-between", alignItems:"space-around", height:"auto", margin: "40px", padding: "50px" }}>
                        <div>
                            <p style={{ color: "#390153" }}>
                            {r.AO_CHILD_name} | {r.AO_CHILD_firstname} |{" "}
                            {new Date(r.AO_SLOT_start).toLocaleDateString("fr-FR")} de{" "}
                            {new Date(r.AO_SLOT_start)
                                .toLocaleTimeString("fr-FR")
                                .substring(
                                0,
                                new Date(r.AO_SLOT_start).toLocaleTimeString("fr-FR")
                                    .length - 3
                                )}{" "}
                            à{" "}
                            {new Date(r.AO_SLOT_end)
                                .toLocaleTimeString("fr-FR")
                                .substring(
                                0,
                                new Date(r.AO_SLOT_end).toLocaleTimeString("fr-FR")
                                    .length - 3
                                )}{" "}
                            | {r.AO_EAJE_name}
                            </p>
                        </div>
                        <div>
                            <button
                            className="full_btn"
                            onClick={() => {
                                setModal(true);
                            }}
                            >
                            Supprimer
                            </button>
                        </div>
                    </div>
                </div>
                </Col>
                <Col lg="1" />
            </Row>
            <div
                style={{
                display: isModalOpened ? "flex" : "none",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                justifyContent: "center",
                alignItems: "center"
                }}
            >
                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    width: "5Ovw",
                    minWidth: "300px",
                    height: "350px",
                    backgroundColor: "#fcfaf7",
                    borderRadius: "50px",
                    padding: "40px"
                }}
                >
                {isModalOpened ? (
                    <div>
                    <div style={{ margin: "20px" }}>
                        <h4>Confirmation</h4>
                        <p>Souhaitez-vous bien annuler le créneau sélectionné ?</p>
                    </div>
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "50px"
                        }}
                    >
                        <button
                        onClick={() => {
                            setModal(false);
                        }}
                        style={{ backgroundColor: "#9e50c2", color: "#fcfaf7" }}
                        >
                        Annuler
                        </button>
                        <button
                        onClick={() => {
                            setCancelledBooking(true);
                            cancelBooking();
                            setModal(false);
                        }}
                        style={{
                            backgroundColor: "#fcd260",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                        >
                        Oui, je confirme
                        </button>
                    </div>
                    </div>) : ("")}
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({ userReducer: { user }, authenticationReducer: { accessToken } }) => ({
        user,
        accessToken
    })
    )(ResaParent);
