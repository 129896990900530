import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import axios from "axios";

function ResetPasswordRequest() {
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get("role");
  const [confirmationMessage, displayConfirmationMessage] = useState(false);
  const [email, setEmail] = useState("");

  const sendResetPasswordLink = event => {
    event.preventDefault();
    axios
      .post("/authentication/reset_password", {
        email,
        role
      })
      .then(({ data }) => {
        displayConfirmationMessage(true);
      });
  };

  if (role === "parent" || role === "user")
    return (
      <>
        <Header />
        <div className="bg_babily">
          <Container style={{ paddingTop: "100px", paddingBottom: "150px" }}>
            <Row>
              <Col md={{ span:6, offset:3}} xs={12}>
                <Card
                  className="bg_purple_dark form_pro"
                  style={{
                    borderRadius: "20px",
                    width: "100%",
                    padding: "20px"
                  }}
                >
                  <Card.Body>
                    <h2 style={{ color: "white", textAlign: "center" }}>
                      Récupération du mot de passe
                    </h2>
                    <Form onSubmit={sendResetPasswordLink}>
                      <FormGroup>
                        <FormLabel>
                          Veuillez renseigner votre adresse email.
                    </FormLabel>
                        <FormControl
                          type="email"
                          required
                          placeholder="Email"
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                        />
                      </FormGroup>
                      {confirmationMessage && (
                        <p>
                          Un email de réinitialisation de mot de passe a été envoyé
                          à l'adresse renseignée si celle-ci est associée à un
                          compte Babily.
                    </p>
                      )}
                      <div className="form_pro_btn">
                        <Button type="submit" className="button">
                          {!confirmationMessage
                            ? "Confirmer"
                            : "Renvoyer le lien de réinitialisation"}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
        <Footer />
      </>
    );
  else return null;
}

export default ResetPasswordRequest;
