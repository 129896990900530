import React, { Component } from "react";
import {
  Button,
  ListGroup,
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl
} from "react-bootstrap";

class FormEnfant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
      child: {
        lastNameChild: "",
        firstNameChild: "",
        birth: ""
      },
      emptyfield: "",
      moreThan: "",
      renseigné: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.addChildren = this.addChildren.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.supprChildren = this.supprChildren.bind(this);
  }

  handleChange(event) {
    const { child } = this.state;
    child[event.target.name] = event.target.value;
    this.setState({
      child
    });
  }

  nextStep() {
    const { children } = this.state;
    if (children.length === 0) {
      this.setState({ renseigné: "Vous n'avez renseigné aucun enfant" });
    } else {
      this.props.onClickNext(this.state);
    }
  }

  addChildren() {
    const { child, children } = this.state;
    if (
      child.lastNameChild !== "" &&
      child.firstNameChild !== "" &&
      child.birth !== ""
    ) {
      if (children.length <= 2) {
        children.push(child);
        this.setState({
          child: {
            lastNameChild: "",
            firstNameChild: "",
            birth: ""
          },
          children: children
        });
      } else {
        this.setState({ moreThan: "3 enfants maximum" });
      }
    } else {
      this.setState({ emptyfield: "Champ manquant" });
    }
  }

  supprChildren(index) {
    const { children } = this.state;
    this.setState({
      children: children.filter((c, i) => i !== index)
    });
  }

  render() {
    const { child, children, emptyfield, renseigné, moreThan } = this.state;
    const { nextStep, supprChildren, handleChange, addChildren } = this;

    return (
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "50px"
        }}
      >
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} xs={12}>
              <div style={{ margin: "30px 0px 80px 0px" }}>
                <div style={{ marginRight: "140px" }}>
                  <h3>Vos enfants</h3>
                  {children.length === 0 &&
                    "Vous n'avez renseigné aucun enfant"}
                </div>
                {children.map((child, i) => {
                  return (
                    <ListGroup key={`child-${i - Math.random()}`} horizontal>
                      <ListGroup.Item
                        style={{
                          minWidth: "140px",
                          minHeight: "60px",
                          textAlign: "center"
                        }}
                      >
                        {child.lastNameChild}
                      </ListGroup.Item>
                      <ListGroup.Item
                        style={{
                          minWidth: "140px",
                          minHeight: "60px",
                          textAlign: "center"
                        }}
                      >
                        {child.firstNameChild}
                      </ListGroup.Item>
                      <ListGroup.Item
                        style={{
                          minWidth: "140px",
                          minHeight: "60px",
                          textAlign: "center"
                        }}
                      >
                        {child.birth}
                      </ListGroup.Item>
                      <ListGroup.Item
                        style={{
                          minWidth: "140px",
                          minHeight: "60px",
                          textAlign: "center"
                        }}
                      >
                        <button
                          onClick={() => supprChildren(i)}
                          className="button"
                        >
                          suppr
                        </button>
                      </ListGroup.Item>
                    </ListGroup>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} xs={12}>
              <div>
                <h4>Ajouter un enfant</h4>
                <FormGroup>
                  <FormLabel>Nom</FormLabel>
                  <FormControl
                    type="nom"
                    name="lastNameChild"
                    value={child.lastNameChild}
                    onChange={handleChange}
                    placeholder="Nom..."
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup style={{ paddingTop: "20px" }}>
                  <FormLabel>Prénom</FormLabel>
                  <FormControl
                    type="prenom"
                    name="firstNameChild"
                    value={child.firstNameChild}
                    onChange={handleChange}
                    placeholder="Prénom..."
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup style={{ paddingTop: "20px" }}>
                  <FormLabel>Date de naissance</FormLabel>
                  <FormControl
                    type="date"
                    name="birth"
                    value={child.birth}
                    onChange={handleChange}
                    placeholder="Date de naissance..."
                    className="form-control"
                  />
                </FormGroup>

                <div
                  style={{
                    backgroundColor: "#FF4D4D",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center"
                  }}
                >
                  {emptyfield}
                </div>
                <div
                  style={{
                    backgroundColor: "#FF4D4D",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center"
                  }}
                >
                  {renseigné}
                </div>
                <div
                  style={{
                    backgroundColor: "#FF4D4D",
                    color: "white",
                    marginTop: "10px",
                    textAlign: "center"
                  }}
                >
                  {moreThan}
                </div>
                <div style={{ marginTop: "30px" }}>
                  <p>
                    Ajouter
                    <Button
                      style={{
                        borderRadius: "50%",
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#9E50C2",
                        marginLeft: "30px"
                      }}
                      onClick={addChildren}
                    >
                      +
                    </Button>
                  </p>
                </div>
                <div style={{ padding: "50px 0px 130px 0px" }}>
                  <Button
                    className="border_btn"
                    style={{ float: "left" }}
                    onClick={this.props.onClickBefore}
                  >
                    Précédent
                  </Button>
                  <Button
                    className="full_btn"
                    style={{ float: "right" }}
                    onClick={nextStep}
                  >
                    Suivant
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}
export default FormEnfant;
