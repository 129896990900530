import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Col, Row, Container, Button } from "react-bootstrap";
import ModifUserPerso from "../components/ModifUserPerso";
import ModifUserConexion from "../components/ModifUserConexion";
import { NavLink } from "react-router-dom";

class UserModif extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column"}}>
        <Header />
        <Container style={{ marginBottom: "100px" }}>
          <div className="filariane" style={{ paddingTop: "30px" }}>
            <NavLink to={"/"} style={{ color: "#9E50C2" }}>
              BABILY -{" "}
            </NavLink>
            <NavLink to={"/user"} style={{ color: "#9E50C2" }}>
              MON PROFIL -{" "}
            </NavLink>
            <NavLink to={"/usermodif"} style={{ color: "#9E50C2" }}>
              MODIFER MON PROFIL
            </NavLink>
          </div>
          <h1 style={{ textAlign: "center", padding: "80px 0px 30px 0px" }}>
            Modifier mon profil
          </h1>
          <Row>
            <Col lg="6" sm="12" xs="12">
              <ModifUserPerso />
            </Col>
            <Col lg="6" sm="12" xs="12">
              <ModifUserConexion />
            </Col>
          </Row>
          <Row>
            <Col lg="12" sm="12" xs="12" style={{ marginTop: "40px" }}>
              <NavLink to={"/user"}>
                <Button className="button" style={{ float: "right", marginRight: "20px"}}>
                  Retour
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container> 
        <Footer />
      </div>
    );
  }
}

export default UserModif;
