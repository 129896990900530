import axios from "axios";
import { getChildren } from "./childAction";

export const setUser = () => {
  return (dispatch, getState) => {
    axios
      .get(`/api/parents/infos`)
      .then(({ data }) => {
        dispatch({ type: "SET_USER", user: data });
        dispatch(getChildren(data.AO_PARENT_id));
      })
      .catch(err => dispatch({ type: "SET_USER", user: null }));
  };
};

export const getUserBookings = () => {
  return (dispatch, getState) => {
    axios
      .get(`/resa/resas`)
      .then(({ data }) => {
        dispatch({ type: "GET_BOOKINGS", resa: data });
      })
      .catch(err => console.log(err));
  };
};

export const getFavCreches = () => {
  return (dispatch, getState) => {
    axios
      .get(`/api/parents/creches`)
      .then(({ data }) => {
        dispatch({ type: "GET_CRECHESFAV", creches: data });
      })
      .catch(err => console.log(err));
  };
};
