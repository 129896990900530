import React, { useEffect, Fragment }  from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { connect } from "react-redux";
import SearchBar from "../components/SearchBar";
import CrecheFav from "../components/CrecheFav";
import { getFavCreches } from "../actions/userActions";

const MesCreches = (props) => {
    const {creches, dispatch} = props;
    
    useEffect(() =>
	{
        dispatch(getFavCreches())
    }, [])

    return (
        <div className="bg_cream">
            <Header/>
                <Container style={{ marginTop:"30px" }}>
                    <div className="filariane">
                        <NavLink to={"/"} style={{ color: "#9E50C2" }}>BABILY - </NavLink>
                        <NavLink to={"/user"} style={{ color: "#9E50C2" }}>MON PROFIL - </NavLink>
                        <NavLink to={"/user/dashboard"} style={{ color: "#9E50C2" }}>MES CRÈCHES</NavLink>
                    </div>
                    <h2>Mes crèches</h2>
                </Container>
                    <div style={{ width: "100%", padding:'50px', backgroundColor: "#390153" }}>
                        <h2 style={{ color: "white", textAlign: "center", fontSize: "1em"}}>Trouver une nouvelle crèche</h2>
                        <SearchBar style={{ paddingBottom: "30px"}}/>
                        <p style={{ textAlign: "center", paddingTop: "10px", color:'#fcfaf7' }}>Nom d’une crèche, Commune, Quartier, Code postal, etc</p>
                    </div>
                <Container style={{ marginBottom: "10vw", marginTop:"30px", display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                    {creches && creches.map((creche, i) =>
                    <CrecheFav creche={creche} key={i + '_creche'}/>)}
                </Container>
            <Footer/>
        </div>
    )
}

export default connect(({ userReducer: { creches } }) => ({
    creches
  }))(MesCreches);