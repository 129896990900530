import React from "react";
import { connect } from "react-redux";
import { filterByDistance } from "../actions/searchActions";
import { Form } from "react-bootstrap";

function DistanceFilter({ distanceFilter, dispatch }) {
  return (
    <Form.Control
      as="select"
      onChange={event => dispatch(filterByDistance(event.target.value))}
      defaultValue={distanceFilter}
    >
      <option value="500">500 m</option>
      <option value="1000">1 km</option>
      <option value="2000">2 km</option>
      <option value="5000">5 km</option>
      <option value="10000">10 km</option>
      <option value="25000">25 km</option>
    </Form.Control>
  );
}

export default connect(({ searchReducer: { distanceFilter } }) => ({
  distanceFilter
}))(DistanceFilter);
