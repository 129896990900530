import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "minireset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import Reducer from "./reducers";
import App from "./App";
import axios from "axios";
import { host } from "./conf";

const store = createStore(Reducer, applyMiddleware(thunk));

axios.defaults.baseURL = host;
axios.interceptors.request.use(
  config => {
    const accessToken = store.getState().authenticationReducer.accessToken;
    if (accessToken && config.url.slice(0, 4) !== "http") {
      config.headers.Authorization = "Bearer " + accessToken;
    }
    return config;
  },
  error => Promise.reject(error)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
