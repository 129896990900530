import React, { useState } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { getAvailableSlots } from "../actions/availableSlotsActions";
import { getUserBookings } from "../actions/userActions";
import { setCurrentPage } from "../actions/currentPageActions";
import { Link } from "react-router-dom"

const SlotCard = (props) => {
    const [isClicked, setIsClicked] = useState(false);
    const {slot, children, nurseryId, dispatch, resa, user} = props;
    const [selectedChildName, setSelectedChildName] = useState();
    const [selectedChild, setSelectedChild] = useState();
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [postIsDone, setPostIsDone] = useState(true);
    const [alreadyBooked, setAlreadyBooked] = useState(false);

    const bookSlot = () => {
      console.log("s", selectedChild);
      axios
        .post(`/resa/parents/${user.AO_PARENT_id}/resas`, {
          childId: selectedChild,
          slotId: slot.AO_SLOT_id
        })
        .then(() => {
          setTimeout(() => dispatch(getAvailableSlots(nurseryId)), 2000);
        })
        .catch(function(erreur) {
          console.log(erreur);
          setPostIsDone(false);
        });
    };
  

    //compare available slots and already booked ones:
    const compare = (child) => {
        const compareResa = resa && !resa.find(r => { return(
            r.AO_CHILD_id === child.AO_CHILD_id 
            && r.AO_SLOT_start === slot.AO_SLOT_start
            && r.AO_SLOT_end === slot.AO_SLOT_end)
        })

        if (!compareResa) {
            setAlreadyBooked(true)
        }
        
    }

    return (

        <div>
            <div style={{ width: '13rem', textAlign:"center", margin:"20px 10px", height:"auto", border:"1px solid rgba(0, 0, 0, 0.1)", borderRadius:"10px", padding:"20px", boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.2)", backgroundColor:"white"}}>
                <div>
                    <h4>le {new Date(slot.AO_SLOT_start).toLocaleDateString('fr-FR')}</h4>
                    <p>
                    de {new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').length - 3)} à {new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').length - 3)}
                    </p>
                    {!children[0].AO_LOVE_child && <p style={{fontSize:"0.7em"}}>Veuillez vous connecter ou vous inscrire afin de sélectionner ce créneau</p>}
                    <div style={{borderRadius:"30px", backgroundColor:"#fcd260", display:"flex",flexDirection:"column", textAlign:"center", alignItems:"center"}}>
                        { children[0].AO_LOVE_child ? <div 
                            style={{backgroundColor:"#fcd260", color:"white", cursor:"pointer", borderRadius: "50px", padding: "10px 20px", fontFamily: "poppinsmedium", letterSpacing: "0.05em", fontSize: "0.8em", border: 0}} 
                            id="dropdown-basic-button" 
                            onClick={()=>{
                                setIsClicked(!isClicked);
                                dispatch(getUserBookings());
                                }}>{isClicked ? "Sélectionner l'enfant" : "Choisir ce créneau"}
                        </div> :
                        <Link to={ "/connexion" }>
                            <button 
                            style={{backgroundColor: "#FCD260", color:"white"}}
                            onClick={()=>{
                                dispatch(setCurrentPage("/creche/"+nurseryId));
                                }}
                            >Me connecter - M'inscrire
                            </button>
                        </Link>}
                        {isClicked && children.map((child, i) => 
                            <p key={i + '_child'} style={{marginTop:"10px", padding:"10px", cursor:"pointer", backgroundColor:"#fcfaf7", borderRadius:"30px", width:"7em"}} variant="primary" 
                            // disabled={compare(child)}
                            onClick={() => {
                                setSelectedChild(child.AO_LOVE_child);
                                setSelectedChildName(child.AO_CHILD_firstname);
                                console.log("child", child)
                                compare(child);
                                }}>
                            {child.AO_CHILD_firstname}
                            </p>
                        )}
                    </div>                                
                </div>
            </div>
            <div style={{display: selectedChildName ? "flex" : "none", position:"fixed", top:0, left:0, width: "100vw", height: "100vh", zIndex:1, backgroundColor:"rgba(0, 0, 0, 0.6)", justifyContent:"center", alignItems:"center"}}>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"space-around", width: "5Ovw", minWidth:"300px", height:"350px", backgroundColor:"#fcfaf7", borderRadius:"50px", padding:"40px"}}>
                    {isConfirmed ?
                    <div>
                        {postIsDone ?
                        <div>
                            <h4>Merci !</h4>
                            <p>
                                Votre réservation pour le {new Date(slot.AO_SLOT_start).toLocaleDateString('fr-FR')} de {new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').length - 3)} à {new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').length - 3)} pour {selectedChildName} a bien été prise en compte.
                            </p> 
                        </div> :
                        <div>
                            <h4>Oups...</h4>
                            <p>
                                La réservation souhaitée n'a pas pu être effectuée. Veuillez réessayer.
                            </p>
                        </div>}
                    </div> : 
                    alreadyBooked ?
                    <div>
                        <h4>Réservation impossible</h4>
                        <p>
                        Attention, {selectedChildName} est déjà inscrit.e à un créneau le {new Date(slot.AO_SLOT_start).toLocaleDateString('fr-FR')} de {new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').length - 3)} à {new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').length - 3)}. Merci de modifier vos critères.
                        </p>
                    </div> :
                    <div>
                        <h4>Confirmation</h4>
                        <p>
                        Souhaitez-vous bien réserver un créneau le {new Date(slot.AO_SLOT_start).toLocaleDateString('fr-FR')} de {new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_start).toLocaleTimeString('fr-FR').length - 3)} à {new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').substring(0, new Date(slot.AO_SLOT_end).toLocaleTimeString('fr-FR').length - 3)} pour {selectedChildName} ?
                        </p>
                    </div>
                    }
                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                        {alreadyBooked ? 
                        <button 
                            onClick={() => {
                                setIsClicked(false);
                                setSelectedChildName(null);
                                setAlreadyBooked(false)
                                }}
                            style={{display: alreadyBooked ? "block" : "none", backgroundColor:"#9e50c2", color:"#fcfaf7"}}>retour
                        </button> :
                        <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <button 
                                onClick={() => {
                                    setIsClicked(false);
                                    setSelectedChildName(null);
                                    }}
                                style={{display: isConfirmed ? "none" : "block", backgroundColor:"#9e50c2", color:"#fcfaf7"}}>Annuler
                            </button>
                            <button 
                                onClick={() => {
                                    setIsConfirmed(true);
                                    bookSlot()
                                    }} 
                                style={{display: isConfirmed ? "none" : "block", backgroundColor:"#fcd260", color:"#fcfaf7", marginLeft:"20px"}}>Oui, je confirme
                            </button>
                            <button 
                                onClick={() => {
                                    setIsClicked(false);
                                    setSelectedChildName(null);
                                    }} 
                                style={{display: isConfirmed ? "block" : "none", backgroundColor:"#fcd260", color:"#fcfaf7"}}>Fermer
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default connect(({ userReducer: { resa, user }, authenticationReducer: { accessToken }, currentPageReducer: { currentPage }}) => ({
    resa,
    user,
    accessToken, 
    currentPage
}))(SlotCard);
