import React, { useState } from "react";
import {
  Card,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { setUser } from "../actions/userActions";
import { setAccessToken } from "../actions/authenticationActions";
import { connect } from "react-redux";
import "./Connexion.css";

const ParentConnect = ({ dispatch, currentPage, user }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [display401Message, setDisplay401Message] = useState(false);
  const history = useHistory();

  const login = event => {
    event.preventDefault();
    axios
      .post(
        "/authentication/login",
        { email, password },
        { withCredentials: true }
      )
      .then(({ data, headers }) => {
        dispatch(setAccessToken(data.accessToken));
        dispatch(setUser());
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) setDisplay401Message(true);
        }
      });
  };

  const connectPanel = {
    borderRadius: "20px",
    width: "100%",
    padding: "20px"
  };

  return (
    <Card className="bg_purple_dark form_pro" style={connectPanel}>
      <Card.Body>
        <Form onSubmit={login}>
          <FormGroup>
            <FormLabel>Votre adresse email</FormLabel>
            <FormControl
              type="email"
              placeholder="Email"
              onChange={e => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Votre mot de passe</FormLabel>
            <FormControl
              type="password"
              placeholder="Mot de passe"
              onChange={e => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </FormGroup>
          {display401Message && <p>Email ou mot de passe incorrect</p>}
          <div className="form_pro_btn">
            <div className="links">
              <Link to="/familysignup" className="link">
                Pas encore inscrit ?
              </Link>
              <Link to="/requestresetpassword?role=parent" className="link">
                Mot de passe oublié
              </Link>
            </div>
            <div>
              <Button type="submit" className="button">
                Se connecter
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default connect(
  ({ currentPageReducer: { currentPage }, userReducer: { user } }) => ({
    currentPage,
    user
  })
)(ParentConnect);
