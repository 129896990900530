const initialState = {
	availableSlots: null
}


const availableSlotsReducer = (state = initialState, action) =>
{
	switch (action.type) {
		case "GET AVAILABLE SLOTS":
			return {
				...state, availableSlots: action.availableSlots
			};
		default:
			return state;
	}
	
};

export default availableSlotsReducer;
