import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prefChannel: props.user.AO_PARENT_prefChannel,
      nightPeace: props.user.AO_PARENT_nightPeace,
      checkedEmail: true,
      checkedSMS: true,
      checkednightPeace: true
    };
  }

  componentDidMount() {
    this.initializeChecks();
  }

  initializeChecks = () => {
    if (this.state.prefChannel === 0) {
      this.setState({ checkedEmail: false, checkedSMS: false });
    }
    if (this.state.prefChannel === 1) {
      this.setState({ checkedEmail: true, checkedSMS: false });
    }
    if (this.state.prefChannel === 2) {
      this.setState({ checkedEmail: false, checkedSMS: true });
    }
    if (this.state.prefChannel === 3) {
      this.setState({ checkedEmail: true, checkedSMS: true });
    }
    if (this.state.nightPeace === 0) {
      this.setState({ checkednightPeace: false });
    }
    if (this.state.nightPeace === 1) {
      this.setState({ checkednightPeace: true });
    }
  };

  modifyNightPeace = () => {
    if (this.state.nightPeace === 1) {
      this.setState(
        { nightPeace: 0, checkednightPeace: false },
        this.sendPrefs
      );
    } else {
      this.setState({ nightPeace: 1, checkednightPeace: true }, this.sendPrefs);
    }
  };

  modifyPrefEmail = () => {
    if (this.state.prefChannel === 0) {
      this.setState(
        { prefChannel: this.state.prefChannel + 1, checkedEmail: true },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 1) {
      this.setState(
        { prefChannel: this.state.prefChannel - 1, checkedEmail: false },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 2) {
      this.setState(
        { prefChannel: this.state.prefChannel + 1, checkedEmail: true },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 3) {
      this.setState(
        { prefChannel: this.state.prefChannel - 1, checkedEmail: false },
        this.sendPrefs
      );
    }
  };

  modifyPrefSMS = () => {
    if (this.state.prefChannel === 0) {
      this.setState(
        { prefChannel: this.state.prefChannel + 2, checkedSMS: true },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 1) {
      this.setState(
        { prefChannel: this.state.prefChannel + 2, checkedSMS: true },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 2) {
      this.setState(
        { prefChannel: this.state.prefChannel - 2, checkedSMS: false },
        this.sendPrefs
      );
    }
    if (this.state.prefChannel === 3) {
      this.setState(
        { prefChannel: this.state.prefChannel - 2, checkedSMS: false },
        this.sendPrefs
      );
    }
  };

  sendPrefs = () => {
    const { prefChannel, nightPeace } = this.state;
    axios
      .put(`/api/parents/me/infos/`, {
        AO_PARENT_prefChannel: prefChannel,
        AO_PARENT_nightPeace: nightPeace
      })
      .then(({ data }) => {
        console.log(data);
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  render() {
    const {
      modifyPrefEmail,
      modifyPrefSMS,
      modifyNightPeace
    } = this;
    const { checkedEmail, checkedSMS, checkednightPeace } = this.state;
    return (
      <div>
        <Row
          style={{
            display: "flex",
            color: "#9E50C2",
            marginBottom: "100px",
            width: "100%",
            margin: "0px"
          }}
          className="cadre2"
        >
          <Col style={{ padding: "10px", width: "35vw", minWidth: "320px"}}>
            <h2 style={{ textAlign: "center" }}>Mes préférences</h2>
            <div style={{ display: "flex", padding: "5px" }}>
              <p>Je préfère être contacté.e : </p>
              <div style={{ paddingLeft: "5px"}}>
                <Form.Check
                  type="checkbox"
                  checked={checkedEmail}
                  onChange={modifyPrefEmail}
                  label="par email"/>
                <Form.Check
                  type="checkbox"
                  checked={checkedSMS}
                  onChange={modifyPrefSMS}
                  label="par sms"/>
                </div>
              </div>
              <div style={{ display: "flex", padding: "5px" }}>
                <p>J'accepte d'être contacté.e la nuit (21h-6h) : </p>
                <Form.Check style={{ marginLeft: "5px"}}
                  type="checkbox"
                  checked={checkednightPeace}
                  onChange={modifyNightPeace}/>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  ({ userReducer: { user }, authenticationReducer: { accessToken } }) => ({
    user,
    accessToken
  })
)(Preferences);
