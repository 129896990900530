import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

class Faq extends Component {
  render() {
    return (
        <div>
          <Header/>
          <Footer/>
        </div>
    );
  }
}
export default Faq;