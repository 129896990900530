import React, { useState, Fragment, Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ChildInfo from "../components/ChildInfo";
import { Button, ListGroup, Col, Row, Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { getChildren } from "../actions/childAction";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Preferences from "../components/Preferences";
import axios from "axios";

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ModifyUser: true,
            addOneChildren: false,
            firstName: "",
            lastName: "",
            birthDate: "",
            validate: false,
            confirmChanges: false,
            postHasFailed: false
        };
        this.ModifyUser = this.ModifyUser.bind(this);
    }
    componentDidUpdate(prevProps) {
        const { user, getChildren } = this.props;
        if (prevProps.user !== user) {
            getChildren(user.AO_PARENT_id);
        }
    }

    ModifyUser() {
        this.setState(state => ({
            ModifyUser: !state.ModifyUser
        }));
    }

    createChildren = () => {
        const parentid = this.props.user.AO_PARENT_id;
        const { firstName, lastName, birthDate } = this.state;
        const children = { lastName: lastName, firstName: firstName, birthDate: birthDate };
        axios
            .post(`/api/user/${parentid}/children`, children)
            .then()
            .catch(err => {
                console.log(err);
                this.setState({ postHasFailed: true });
            });
    };

    addChild = () => {
        const { firstName, lastName, birthDate } = this.state;
        return (
            <div
                style={{
                    height: "auto",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <div>
                    <input
                        type="text"
                        id="autocomplete1"
                        className="form-control"
                        style={{
                            width: "100%",
                            borderRadius: "50px",
                            marginBottom: "10px"
                        }}
                        placeholder="prénom"
                        onChange={event => this.setState({ firstName: event.target.value })}
                    />
                    <input
                        type="text"
                        id="autocomplete2"
                        className="form-control"
                        style={{
                            width: "100%",
                            borderRadius: "50px",
                            marginBottom: "10px"
                        }}
                        placeholder="nom de famille"
                        onChange={event => this.setState({ lastName: event.target.value })}
                    />
                    <p style={{ margin: "10px 0px 3px 5px" }}>né.e le :</p>
                    <input
                        type="date"
                        id="autocomplete3"
                        className="form-control"
                        style={{
                            width: "100%",
                            borderRadius: "50px",
                            marginBottom: "10px"
                        }}
                        placeholder="date de naissance"
                        onChange={event => this.setState({ birthDate: event.target.value })}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        style={{
                            backgroundColor: firstName && lastName && birthDate ? "#fcd260" : "grey",
                            color: firstName && lastName && birthDate ? "white" : "lightgrey",
                            cursor: firstName && lastName && birthDate ? "pointer" : "auto",
                            borderRadius: "40px",
                            padding: "10px 20px",
                            fontLast: "poppinsmedium",
                            letterSpacing: "0.05em",
                            fontSize: "0.8em",
                            border: 0
                        }}
                        onClick={() => (firstName && lastName && birthDate ? this.setState({ validate: true }) : "")}
                    >
                        Valider
                    </button>
                </div>
                <Fragment>{this.modal()}</Fragment>
            </div>
        );
    };

    confirmation = () => {
        const { firstName, lastName, birthDate } = this.state;
        return (
            <Fragment>
                <h4>Confirmation</h4>
                <p>
                    Confirmez-vous bien l'ajout de {firstName} {lastName}, né.e le {new Date(birthDate).toLocaleDateString("fr-FR")}?
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "50px" }}>
                    <button
                        onClick={() => {
                            this.setState({ validate: false });
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "#fcfaf7"
                        }}
                    >
                        Non, Annuler
                    </button>
                    <button
                        onClick={() => {
                            this.setState({ confirmChanges: true });
                            this.createChildren();
                        }}
                        style={{
                            backgroundColor: "#fcd260",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Oui, je confirme
                    </button>
                </div>
            </Fragment>
        );
    };

    confirmed = () => {
        const { user, getChildren } = this.props;
        return (
            <Fragment>
                <h4>Merci !</h4>
                <p>Les modifications souhaitées ont bien été effectuées</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            this.setState({ validate: false });
                            this.setState({ addOneChildren: false });
                            getChildren(user.AO_PARENT_id);
                        }}
                        style={{
                            backgroundColor: "#fcd260",
                            color: "#fcfaf7",
                            marginLeft: "20px"
                        }}
                    >
                        Fermer
                    </button>
                </div>
            </Fragment>
        );
    };

    failed = () => {
        return (
            <Fragment>
                <h4>Oups...</h4>
                <p>Une erreur est survenue. Veuillez réessayer.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                        onClick={() => {
                            this.setState({ validate: false });
                            this.setState({ confirmChanges: false });
                            this.setState({ postHasFailed: false });
                        }}
                        style={{
                            backgroundColor: "#9e50c2",
                            color: "white",
                            marginLeft: "20px"
                        }}
                    >
                        Réessayer
                    </button>
                </div>
            </Fragment>
        );
    };

    modal = () => {
        return (
            <div
                style={{
                    display: this.state.validate ? "flex" : "none",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: "5Ovw",
                        minWidth: "300px",
                        height: "350px",
                        backgroundColor: "#fcfaf7",
                        borderRadius: "50px",
                        padding: "40px"
                    }}
                >
                    {this.state.confirmChanges ? (this.state.postHasFailed ? this.failed() : this.confirmed()) : this.confirmation()}
                </div>
            </div>
        );
    };

    render() {
        const { ModifyUser } = this;
        const { user, children } = this.props;
        return (
            <div>
                <Header />
                <Container style={{ marginBottom: "100px" }}>
                    <div className="filariane" style={{ paddingTop: "30px" }}>
                        <NavLink to={"/"} style={{ color: "#9E50C2" }}>
                            BABILY -{" "}
                        </NavLink>
                        <NavLink to={"/user"} style={{ color: "#9E50C2" }}>
                            MON PROFIL
                        </NavLink>
                    </div>
                    <h1 style={{ textAlign: "center", padding: "80px 0px 30px 0px" }}>bienvenue {user && user.AO_PARENT_firstname}</h1>
                    <Row style={{ display: "flex", color: "#9E50C2" }}>
                        <Col lg="6" sm="12" xs="12">
                            <div className="cadre" style={{ minHeight: "530px" }}>
                                <h2 style={{ marginTop: "40px", textAlign: "center" }}>Mon profil</h2>
                                {user && (
                                    <ListGroup>
                                        <ListGroup.Item>Nom : {user.AO_PARENT_name}</ListGroup.Item>
                                        <ListGroup.Item style={{ marginTop: "30px" }}>Prénom : {user.AO_PARENT_firstname}</ListGroup.Item>
                                        <ListGroup.Item style={{ marginTop: "30px" }}>Téléphone : {user.AO_PARENT_phone}</ListGroup.Item>
                                        <ListGroup.Item style={{ marginTop: "30px" }}>Email : {user.AO_PARENT_email}</ListGroup.Item>
                                    </ListGroup>
                                )}
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                    <Button style={{ marginTop: "30px" }} className="button" onClick={ModifyUser}>
                                        Modifier
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" sm="12" xs="12">
                            <div className="cadre" style={{ height: "530px", overflow: "scroll", overflowX: "hidden" }}>
                                <h2 style={{ marginTop: "40px", textAlign: "center" }}>Mes enfants</h2>
                                <button
                                    className="full_btn"
                                    onClick={() => {
                                        this.setState({ addOneChildren: !this.state.addOneChildren });
                                    }}
                                >
                                    {this.state.addOneChildren ? "fermer" : "ajouter un enfant"}
                                </button>
                                {this.state.addOneChildren && this.addChild()}
                                {children &&
                                    children.map((child, i) => (
                                        <div style={{ display: "flex", alignItems: "center" }} key={i + "_child"}>
                                            <ChildInfo key={child.AO_CHILD_id} parentid={user.AO_PARENT_id} child={child} />
                                        </div>
                                    ))}
                            </div>
                        </Col>
                    </Row>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        {this.state.ModifyUser ? "" : <Redirect to="/usermodif" />}

                        <div
                            style={{
                                border: "solid 1px #9E50C2",
                                width: "500px",
                                margin: "50px 0px 80px 0px"
                            }}
                        ></div>
                        {user && <Preferences user={user} />}
                    </div>
                </Container>
                <Footer />
            </div>
        );
    }
}

const mstp = state => ({
    user: state.userReducer.user,
    children: state.childReducer.children
});

const mdtp = dispatch => {
    return {
        getChildren: parentid => dispatch(getChildren(parentid))
    };
};

export default connect(mstp, mdtp)(User);
