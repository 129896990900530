import axios from "axios";
import haversine from "../helpers/haversine";

export const setDistanceFilter = distance => {
  return { type: "SET_DISTANCE_FILTER", distanceFilter: distance };
};

export const initializeNurseries = () => {
  return (dispatch, getState) => {
    const { currentLocation } = getState().searchReducer;
    axios
      .get(
        `/api/nurseries?longitude=${currentLocation.lng}&latitude=${currentLocation.lat}`
      )
      .then(({ data }) => {
        dispatch({ type: "INITIALIZE_NURSERIES", nurseries: data });
        const { distanceFilter } = getState().searchReducer;
        dispatch(filterByDistance(distanceFilter));
      });
  };
};

export const setDateTimeFilter = (date, startTime, endTime) => {
  if (!date) return { type: "SET_DATE_TIME_FILTER", dateTimeFilter: null };
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setHours(startTime.hours, startTime.minutes, 0);
  endDate.setHours(endTime.hours, endTime.minutes, 0);
  return {
    type: "SET_DATE_TIME_FILTER",
    dateTimeFilter: { startDate, endDate }
  };
};

export const setCurrentLocation = (lat, lng, address) => {
  return {
    type: "SET_CURRENT_LOCATION",
    currentLocation: { lat: lat, lng: lng, address: address }
  };
};

const filterNurseries = filteredNurseries => {
  return {
    type: "FILTER_NURSERIES",
    filteredNurseries
  };
};

export const filterByDistance = distance => {
  return (dispatch, getState) => {
    if (distance) dispatch(setDistanceFilter(distance));
    const {
      currentLocation,
      dateTimeFilter,
      nurseries,
      distanceFilter
    } = getState().searchReducer;
    const filteredNurseries = nurseries.filter(
      nursery =>
        haversine(
          [currentLocation.lat, currentLocation.lng],
          [nursery.AO_EAJE_latitude, nursery.AO_EAJE_longitude]
        ) < distanceFilter
    );
    dispatch(filterNurseries(filteredNurseries));
    if (dateTimeFilter) {
      dispatch(filterByDateTime());
    }
  };
};

export const filterByDateTime = () => {
  return (dispatch, getState) => {
    const { dateTimeFilter, filteredNurseries } = getState().searchReducer;
    if (filteredNurseries.length > 0) {
      axios
        .post(
          "/api/nurseries/availableslots",
          filteredNurseries.map(nursery => nursery.AO_EAJE_id)
        )
        .then(({ data }) =>
          filteredNurseries.filter(nursery =>
            data.find(
              slot =>
                new Date(Date.parse(slot.AO_SLOT_start)) <=
                  dateTimeFilter.startDate &&
                new Date(Date.parse(slot.AO_SLOT_end)) >=
                  dateTimeFilter.endDate &&
                slot.AO_SLOT_eaje === nursery.AO_EAJE_id
            )
          )
        )
        .then(nurseries => dispatch(filterNurseries(nurseries)));
    }
  };
};
