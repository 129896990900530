import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ path, component }) {
  const accessToken = useSelector(
    state => state.authenticationReducer.accessToken
  );

  if (accessToken) {
    return <Route path={path} component={component} />;
  } else {
    return <Redirect to="/connexion" />;
  }
}

export default PrivateRoute;
