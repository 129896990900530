import React, { useEffect, Fragment }  from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { Container } from 'react-bootstrap';
import ResaParent from "../components/ResaParent";
import { connect } from "react-redux";
import { getUserBookings } from "../actions/userActions";

const UserResas = (props) => {
    const {resa, dispatch} = props;
    
    useEffect(() =>
	{
        dispatch(getUserBookings())
    }, [])
    
    return(
        <div className="bg_cream">
            <Header/>
            {resa && <Fragment>
                <Container style={{ marginBottom: "10vw", marginTop:"30px" }}>
                    <div className="filariane">
                        <NavLink to={"/"} style={{ color: "#9E50C2" }}>BABILY - </NavLink>
                        <NavLink to={"/user"} style={{ color: "#9E50C2" }}>MON PROFIL - </NavLink>
                        <NavLink to={"/user/reservations"} style={{ color: "#9E50C2" }}>MES RÉSERVATIONS</NavLink>
                    </div>
                    <h2>MES RÉSERVATIONS</h2>
                    {resa.length > 0 ? resa.map((r, i) =>
                        <ResaParent r={r} key={i + '_resa'}/>
                    ) : <p style={{ height: "40vh", textAlign: "center", paddingTop: "50px" }}>Vous n'avez aucune réservation en cours.</p>}
                </Container>
            </Fragment>}
            <Footer />
        </div>
    )
}

export default connect(({ userReducer: { resa } }) => ({
    resa
}))(UserResas);