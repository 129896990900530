import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../components/proStyle.css";
import { Card, Form, FormControl, FormLabel, FormGroup, Button, Container, Table, Row, Col } from 'react-bootstrap'
import Calendar from 'react-calendar';


const ProNewSlot = () =>
{
	const currentNurseryId = useSelector(state => state.connectReducer.currentNurseryId)
	const [slot, setSlot] = useState([])
	const [newSlot, setNewSlot] = useState(
		{
			"AO_SLOT_eaje": currentNurseryId,
			"AO_SLOT_group": 1,
			"AO_SLOT_start": new Date(),
			"AO_SLOT_end": new Date(),
		}
	)
	const [slotQuantity, setSlotQuantity] = useState(0)

	const [day, setDay] = useState(new Date())
	const [modif, setModif] = useState(false)



	useEffect(() =>
	{
		axios.post("/pro/nursery/" + currentNurseryId + "/day/slots", { day })
			.then((response) => { setSlot(response.data) })
	}, [currentNurseryId, day, newSlot, modif])



	const updateSlotHour = (value, name) =>
	{
		day.setHours(value.split(':')[0], value.split(':')[1], 0);
		newSlot[name] = new Date(day);
		setNewSlot(newSlot);
	}

	const updateSlotDate = (date) =>
	{
		setDay(date)

		day.setDate(String(date).split(' ')[2])
		day.setHours(12, 0, 0)

		newSlot.AO_SLOT_start.setDate(String(date).split(' ')[2])
		newSlot.AO_SLOT_end.setDate(String(date).split(' ')[2])
		setNewSlot(newSlot);
	}

	const upDateSlotQuantity = (value) =>
	{
		setSlotQuantity(value);
	}

	const submitSlot = (e) => 
	{
		newSlot.AO_SLOT_eaje = currentNurseryId;
		console.log('newSlot :', newSlot)
		e.preventDefault();
		for (let i = 0; i < slotQuantity; i++) {
			axios.post("/pro/slot/", {
				newSlot
			})
		}
		setModif(!modif)

	}

	const deleteSlot = (AO_SLOT_id) =>
	{
		axios.delete("/pro/slot/" + AO_SLOT_id + "/delete/")
			.then(
				setModif(!modif)
			)
	}



	return (

		<Container style={{ padding: '100px 0 200px 0' }}>



			<Row>
				<Col>
					<Card className="cadre">
						<Card.Body>
							<h2>Ajouter un créneau</h2>
							<Form onSubmit={submitSlot}>

								<Row>
									<Col>
										<FormGroup>
											<Calendar value={day} onChange={(date) => updateSlotDate(date)} className="calendar" />
										</FormGroup>
									</Col>
									<Col>
										<Form.Row>
											<Col>
												<FormGroup>
													<FormLabel>de</FormLabel>
													<FormControl type="time" name="AO_SLOT_start" onChange={(e) => { updateSlotHour(e.target.value, e.target.name) }} required />

												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<FormLabel>à</FormLabel>
													<FormControl type="time" name="AO_SLOT_end" onChange={(e) => { updateSlotHour(e.target.value, e.target.name) }} required />
												</FormGroup>
											</Col>
											<Col>
												<FormLabel>Nombre de places</FormLabel>
												<FormControl type="number" value={slotQuantity} name="slotQuantity" onChange={(e) => { upDateSlotQuantity(e.target.value) }} required />
											</Col>
										</Form.Row>
										<Form.Row>
											<div className='form_pro_btn' style={{ marginTop: "50px" }}>

												<Button type="submit" className='button'>Proposer le créneau</Button>
											</div>
										</Form.Row>


									</Col>
								</Row>
								<br />

							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card className="cadre">
						<Card.Body>
							<Row>
								<Col>
									<h2>Le {new Date(day).toLocaleDateString('fr-FR')} </h2>
									<Table striped responsive hover style={{
										marginTop: "30px",
										width: "100%",
									}}  >
										<thead>
											<tr>
												<td>#</td>
												<td>Jour</td>
												<td>de</td>
												<td>à</td>
												<td>Actions</td>
											</tr>
										</thead>
										<tbody>
											{slot.map((s, i) =>
												<tr key={i + '_slot'}>

													<td>{i + 1}</td>
													<td>{new Date(s.AO_SLOT_start).toLocaleDateString('fr-FR')}</td>
													<td>{new Date(s.AO_SLOT_start).toLocaleTimeString('fr-FR')}</td>
													<td>{new Date(s.AO_SLOT_end).toLocaleTimeString('fr-FR')}</td>
													<td>{s.AO_SLOT_child ? (<Button variant="danger">{s.AO_CHILD_firstname + ' ' + s.AO_CHILD_name}</Button>) : (<Button onClick={() => deleteSlot(s.AO_SLOT_id)}>Supprimer</Button>)}</td>
												</tr>
											)}
										</tbody>
									</Table>

								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Container>
	)
}

export default ProNewSlot;
