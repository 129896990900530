import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ParentConnect from "../components/ParentConnect";
import ProConnect from "../components/ProConnect";

const Connexion = () => {
  return (
    <>
      <Header />
      <div className="bg_babily">
        <Container style={{ paddingTop: "100px", paddingBottom: "150px" }}>
          <h1 style={{ textAlign:'center', marginBottom:'80px'}}>Connexion</h1>
          <Row>
            <Col md={5} xs={12} style={{marginBottom: "40px"}}>
              <h2 style={{ textAlign: "center" }}>Professionnel</h2>
              <ProConnect />
            </Col>
            <Col md={{span:5, offset:1}} xs={12}>
              <h2 style={{ textAlign: "center" }}>Famille</h2>
              <ParentConnect />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default Connexion;
