import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl
} from "react-bootstrap";

class FormEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoConnection: {
        email: "",
        telephone: "",
        password: "",
        isChecked: false
      },
      emailConfirmation: "",
      passwordBis: "",
      falseEmail: "",
      falsePassword: "",
      emptyfield: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInfoConnectionChange = this.handleInfoConnectionChange.bind(
      this
    );
    this.beforSend = this.beforSend.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  toggleChange = () => {
    this.setState(state => ({
      infoConnection: {
        ...this.state.infoConnection,
        isChecked: !this.state.infoConnection.isChecked
      }
    }));
  };

  handleInfoConnectionChange(event) {
    this.setState({
      infoConnection: {
        ...this.state.infoConnection,
        [event.target.name]: event.target.value
      }
    });
  }

  beforSend() {
    const { email, telephone, password, isChecked } = this.state.infoConnection;
    const { passwordBis, emailConfirmation } = this.state;
    if (
      email !== "" &&
      telephone !== "" &&
      password !== "" &&
      passwordBis !== "" &&
      emailConfirmation !== "" &&
      isChecked !== false
    ) {
      if (email === emailConfirmation) {
        if (password === passwordBis) {
          this.props.onClickNext(this.state);
        } else this.setState({ falsePassword: "Mot de passe non valide" });
      } else {
        this.setState({ falseEmail: "Email non valide" });
      }
    } else {
      this.setState({ emptyfield: "Champ manquant" });
    }
  }

  render() {
    const {
      email,
      emailConfirmation,
      telephone,
      password,
      passwordBis,
      emptyfield,
      falseEmail,
      falsePassword
    } = this.state;
    const {
      beforSend,

      handleInfoConnectionChange,
      handleChange
    } = this;
    return (
      <Container>
        <form
          style={{
            paddingTop: "50px"
          }}
        >
          <Row>
            <Col md={{ span: 6, offset: 3 }} xs={12}>
              <FormGroup>
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInfoConnectionChange}
                  placeholder="Email..."
                  className="form-control"
                />
              </FormGroup>
              <FormGroup style={{ paddingTop: "20px" }}>
                <FormLabel>Confirmation email</FormLabel>
                <FormControl
                  type="email"
                  name="emailConfirmation"
                  value={emailConfirmation}
                  onChange={handleChange}
                  placeholder="Email..."
                  className="form-control"
                />
              </FormGroup>
              <FormGroup style={{ paddingTop: "20px" }}>
                <FormLabel>Téléphone</FormLabel>
                <FormControl
                  type="Téléphone"
                  name="telephone"
                  value={telephone}
                  onChange={handleInfoConnectionChange}
                  placeholder="Téléphone..."
                  className="form-control"
                />
              </FormGroup>
              <FormGroup style={{ paddingTop: "20px" }}>
                <FormLabel>Mot de passe</FormLabel>
                <FormControl
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleInfoConnectionChange}
                  placeholder="Mot de passe..."
                  className="form-control"
                />
              </FormGroup>
              <FormGroup style={{ paddingTop: "20px" }}>
                <FormLabel>Confirmation mot de passe</FormLabel>
                <FormControl
                  type="password"
                  name="passwordBis"
                  value={passwordBis}
                  onChange={handleChange}
                  placeholder="Confirmation mot de passe..."
                  className="form-control"
                />
              </FormGroup>
              <div
                style={{
                  backgroundColor: "#FF4D4D",
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                {emptyfield}
              </div>
              <div
                style={{
                  backgroundColor: "#FF4D4D",
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                {falseEmail}
              </div>
              <div
                style={{
                  backgroundColor: "#FF4D4D",
                  color: "white",
                  marginTop: "10px",
                  textAlign: "center"
                }}
              >
                {falsePassword}
              </div>
              <div style={{ paddingTop: "20px" }}>
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.isChecked}
                    onChange={this.toggleChange}
                  />{" "}
                  J’ai lu et accepté les CGU.
                </label>
              </div>
              <div style={{ padding: "50px 0px 150px 0px" }}>
                <Button
                  className="border_btn"
                  style={{ float: "left" }}
                  onClick={this.props.onClickBefore}
                >
                  Précédent
                </Button>
                <Button
                  className="full_btn"
                  style={{ float: "right" }}
                  onClick={beforSend}
                >
                  Envoyer
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    );
  }
}
export default FormEmail;
