const initialState = {
  currentLocation: {
    lat: 45.746463,
    lng: 4.827222,
    address: ""
  },
  dateTimeFilter: null,
  distanceFilter: 5000,
  nurseries: [],
  filteredNurseries: [],
  isGoogleMapsApiLoaded: true
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_LOCATION":
      return { ...state, currentLocation: action.currentLocation };
    case "SET_DISTANCE_FILTER":
      return { ...state, distanceFilter: action.distanceFilter };
    case "SET_DATE_TIME_FILTER":
      return { ...state, dateTimeFilter: action.dateTimeFilter };
    case "INITIALIZE_NURSERIES":
      return { ...state, nurseries: action.nurseries };
    case "FILTER_NURSERIES":
      return { ...state, filteredNurseries: action.filteredNurseries };
    default:
      return state;
  }
};

export default searchReducer;
