import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import rechercheBabily from '../assets/babily-recherche-bureau.jpg';
import reservationBabily from '../assets/Babily_Reservation.png';
import chiffresBabily from '../assets/Babily_Chiffres_Marche.png';

const NotreConcept = () => {
  return (
    <Fragment>
      <Header />
      <Container style={{ marginTop: "30px", paddingBottom: '150px' }}>
        <div className="filariane">
          <NavLink to={"/"} style={{ color: "#9E50C2" }}>BABILY - </NavLink>
          <NavLink to={"/notreConcept"} style={{ color: "#9E50C2" }}>NOTRE CONCEPT</NavLink>
        </div>
        <h1>Notre concept</h1>
        <Row>
          <Col md={{ span: 8, offset: 2 }} sm={{ span: 12 }}>
            <h2>Simplifions l'accès aux crèches</h2>
            <p>La crèche est le mode d'accueil préféré des français pour leurs enfants, mais seulement une famille sur dix a effectivement accès à une place.
            Pourtant, loin de l'imaginaire collectif, les crèches ont de la place, régulière, occasionnelle, ponctuelle... un tiers de leur capacité, en moyenne.</p>
            <p style={{ color: '#390153', fontSize: '1.2em', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}><span >330 millions d'heures</span> d'accueil disponibles en France chaque année.</p>
            <p>Babily est le compagnon des crèches pour la gestion des disponibilités, une initiative parentale née à Lyon en 2016, co-construite avec une diversité de professionnels de la petite enfance.</p>
            <p>Au-delà du logiciel, Babily est joignable par tout canal utile ou pratique (web, mobile, email, sms, whatsapp, messenger, téléphone), et s’appuie notamment sur un calendrier partagé en ligne, où les établissements peuvent renseigner et suivre leurs disponibilités, et les familles peuvent être prévenues sans délai, selon des règles prédéfinies (préinscription, priorité, groupe d’âge, employeur, etc.), consulter et réserver ces disponibilités en toute autonomie, avec confirmation immédiate.</p>
            <p>Engagé dans un pacte gagnant-gagnant, Babily est gratuit pour les familles, ainsi qu’à l’installation, puis n’est payant pour les crèches que s’il est utile, en fonction de son efficacité.</p>
          </Col>
          <Col md={{ span: 8, offset: 2 }} sm={{ span: 12 }} style={{ marginTop:'20px'}}>
            <img src={ chiffresBabily } alt="chiffres babily"></img>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 2 }} sm={{ span: 6 }} style={{ marginTop:'20px'}}>
            <img style={{ objectFit: 'cover'}} src={ rechercheBabily } alt="recherche babily"></img>
          </Col>
          <Col md={{ span: 4 }} sm={{ span: 6 }} style={{ marginTop:'20px'}}>
            <img style={{ objectFit: 'cover'}} src={ reservationBabily } alt="reservation babily"></img>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  )
};

export default NotreConcept;