import React from 'react';
import './Footer.css';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";


function Footer() {
    return (
          <div className="footer-container">
            <footer>
                <Container>
                <Navbar.Brand></Navbar.Brand>
                    <Row>
                        <Col xs={12} md={4}>
                            <ul>
                                <li className="title">Contact</li>
                                <li className="cream">Nicolas Lorut</li>
                                <li><a href="mailto:nicolas@babily.fr">nicolas@babily.fr</a></li>
                                <li className="cream">+33(0) 608 970 282 </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={3}>
                            <ul>
                                <li>
                                    <Link to={"/NotreConcept"}>Notre concept</Link>
                                </li>
                                <li>
                                    <Link to={"/Contact"}>Contact</Link>
                                </li>
                                <li>
                                    <Link to={"/connexion"}>Connexion</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={3}>
                            <ul>
                                <li>
                                    <Link to={"/MentionsLegales"}>Mentions légales</Link>
                                </li>
                                <li>
                                    <Link to={"/ViePrivee"}>Vie privée</Link>
                                </li>
                                <li>
                                    <Link to={"/Faq"}>FAQ</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={2}>
                            <div className="networks">
                                <a className="cream"href=""><FaFacebook size={20} /></a>
                                <a className="cream"href=""><FaLinkedinIn size={20} /></a>
                                <a className="cream"href=""><FaTwitter  size={20}/></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
           </footer>
          </div>
    );
}

export default Footer;