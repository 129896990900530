const initialState = {
  user: null, 
  resa: null,
  creches: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.user };
    case "GET_BOOKINGS":
      return { ...state, resa: action.resa };
    case "GET_CRECHESFAV":
      return { ...state, creches: action.creches };
    default:
      return state;
  }
};

export default userReducer;
