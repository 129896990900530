import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

function PublicRoute({ path, component, currentPage, user }) {
  const accessToken = useSelector(
    state => state.authenticationReducer.accessToken
  );

  if (!accessToken) {
    return <Route path={path} component={component} />;
  } else {
    return user && <Redirect to={currentPage || "/user/dashboard"} />;
  }
}

export default connect(({ currentPageReducer: { currentPage }, userReducer: { user } }) => ({
  currentPage,
  user
})) (PublicRoute);
