import axios from "axios";

export const getAvailableSlots = (nurseryId) => {
    return (dispatch) => {
        axios
          .get(
            "/pro/nursery/"+ nurseryId +"/slots/available"
          )
          .then(({data}) => {
            dispatch({type: "GET AVAILABLE SLOTS", availableSlots: data});
          });
      };
}
