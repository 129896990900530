import { combineReducers } from "redux";
import connectReducer from "./connectReducer";
import searchReducer from "./searchReducer";
import userReducer from "./userReducer";
import childReducer from "./childReducer";
import currentPageReducer from "./currentPageReducer";
import availableSlotsReducer from "./availableSlotsReducer";
import authenticationReducer from "./authenticationReducer";

export default combineReducers({
  connectReducer,
  searchReducer,
  userReducer,
  childReducer,
  availableSlotsReducer,
  authenticationReducer, 
  currentPageReducer
});
