import React, { useState } from 'react'
import "../components/proStyle.css";
import Header from '../components/Header';
import { Row, Col, Container, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import Footer from '../components/Footer';
import { NavLink, Redirect, Link } from 'react-router-dom'
import ProNewSlot from '../components/ProNewSlot'
import axios from "axios"

const ProDashboard = () =>
{
	const [redirectConnect, setRedirectConnect] = useState(false)
	const [redirectNurseryPrefs, setRedirectNurseryPrefs] = useState(false)

	const dispatch = useDispatch()
	const AO_EAJE = useSelector(state => state.connectReducer.AO_EAJE)
	const AO_BOSS_user = useSelector(state => state.connectReducer.AO_BOSS_user)
	const currentNurseryId = useSelector(state => state.connectReducer.currentNurseryId)
	const [modif, setModif] = useState(false)




	if (redirectConnect) {
		return <Redirect to='/connexion' />;
	}
	if (redirectNurseryPrefs) {
		return <Redirect to='/espacePro/preferences' />;
	}

	if (AO_EAJE[0].AO_EAJE_id === 0 || AO_BOSS_user === 0) {
		setRedirectConnect(true)
	}


	const addNursery = () =>
	{
		axios.post("/pro/user/" + AO_BOSS_user + "/nursery")
			.then((result) =>
			{
				dispatch({
					type: "SET_CURRENT_NURSERY",
					currentNurseryId: result.data.insertId
				});
				axios
					.get(`/pro/user/${AO_BOSS_user}/nurseries`)
					.then(response2 =>
					{
						const nurseries = response2.data;
						axios.post(`/pro/nurseries/`, { nurseries }).then(response3 =>
						{
							dispatch({
								type: "SET_CURRENT_NURSERIES",
								AO_EAJE: response3.data
							});
						});
					});


			}
			)
		setRedirectNurseryPrefs(true)
	}

	return (
		<div className="bg_babily">
			<Header />
			<Container style={{ padding: '30px 0 200px 0' }}>
				<Row>
					<div className='filariane'>
						<NavLink style={{ color: "#9E50C2" }} to={"/"}>BABILY - </NavLink>
						<NavLink style={{ color: "#9E50C2" }} to={"/espacePro"}>ESPACE PROFESSIONNEL</NavLink>
					</div>
				</Row>
				<Row>
					<Col style={{ textAlign: 'center', marginTop: '30px' }}><h1>Espace professionnel : gestion des places</h1></Col>
				</Row>
				<Row>
					<Col md="6">
						{currentNurseryId ? (
							<Form.Control as="select" onChange={(e) =>
							{
								dispatch({
									type: "SET_CURRENT_NURSERY",
									currentNurseryId: AO_EAJE[e.target.value].AO_EAJE_id
								});
							}}>
								{AO_EAJE.map((n, i) =>
									<option value={i} key={i + '_eaje_select'}>{(i + 1) + " - " + n.AO_EAJE_name}</option>
								)}
							</Form.Control>
						) : ('')}
					</Col>
					<Col md="3">
						{currentNurseryId ? (
							<Link to='/espacePro/preferences'><Button className='button'>Modifier la crèche</Button></Link>
						) : (' ')}
					</Col>
					<Col md="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button className='button' onClick={() => addNursery()}>Ajouter une crèche</Button>
					</Col>
				</Row>
				<Row>
					{currentNurseryId ? (
						<ProNewSlot />
					) : (
							<div style={{ margin: "50px auto 0 auto" }}>
								{'Pas de crèche'}
							</div>
						)}
				</Row>

			</Container>
			<Footer />
		</div>
	)
}

export default ProDashboard;

